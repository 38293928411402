.arp-content-main-item {
  margin: 20px auto !important;
}

.arp-content-main-container {
  justify-content: space-between;
}

.arp-content-list-item {
  /* border-right: 1px solid #E0E0E0 !important; */
}

.arp-divider-item {
  display: flex;
}

.arp-divider {
  color: 2px solid #E0E0E0 !important;
}

.arp-blog-content-item {
  padding: 10px !important;
}

.arp-blog-content-head {
  color: black !important;
  font-size: 30px !important;
  font-weight: 600 !important;
}

.arp-blog-content-head-item {
  margin: 10px 0px !important;
}

.artical-bullet-points {
  font-size: 10px !important;
  color: black !important;
  width: 50px !important;
}

.artical-bullet-points-selected {
  color: #6A097D !important;
  width: 50px !important;
  font-size: 10px !important;
}

.arrow-up-down {
  color: black !important;
}

.arrow-up-down-selected {
  color: #6A097D !important;
}

.arp-sub-heading-selected,
.arp-main-heading-selected {
  color: #6A097D !important;
}

.arp-sub-heading,
.arp-main-heading {
  color: black !important;
}