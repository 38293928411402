.artical-card-container {
  justify-content: space-between;
}

.artical-card-item {
  margin: 0px auto !important;
}

.help-artical-card-item {
  margin: 0px 0px 30px !important;
  /* 
  border-radius: 15px; */

}

.help-artical-card {
  padding: 25px 10px;
  border-radius: 15px !important;
  min-height: 250px;
  max-height: 250px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25) !important;
}

.help-artical-card:hover {
  border: 1px solid gray;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 6px 24px 0px, rgba(0, 0, 0, 0.1) 0px 0px 0px 1px !important;
  cursor: pointer;
}

.artical-image-wrapper {
  display: flex;
  justify-content: start;
  width: 50px;
  margin-left: 5px;
}

.help-article-head-title {
  font-size: 17px;
  font-weight: 600;
}

.help-article-sub-title {
  font-size: 15px;
  font-weight: 500;
}