.cb-explore-heading-container {
    /* display: inline-flex;
    flex-direction: column; */
    margin: auto;
    text-align: center;
    margin: 20px auto;
    padding: 20px 0px;
    position: sticky;
    top: 73px;
    background-color: #fff;
    transition:
        box-shadow 0.5s ease-in-out,
        background-color 0.2s ease-in-out;
    box-shadow: none;
}
.cb-shodow-and-bg {
    box-shadow: rgba(179, 123, 190, 0.45) 0px 25px 20px -20px;
    transition:
        box-shadow 0.3s ease-in-out,
        background-color 0.3s ease-in-out;
    background-color: #f8ecff;
}
.cb-explore-heading {
    font-size: 40px;
    font-weight: 500;
    text-decoration: underline rgb(105, 9, 124);
    text-underline-offset: 12px;
}

.cb-explore-heading span {
    color: #6a097d;
}

.cb-explore-sub-heading {
    font-size: 20px;
    color: #1f384c;
    margin-top: 20px;
    font-weight: 500;
}

#chatbotnewScrollDescription {
    font-weight: 500;
    color: black;
    font-size: 20px;
    margin-top: 10px;
    line-height: 150%;
    /* 27px */
}

.chatbotnewscrollelement-main-image {
    width: 100%;
    object-fit: contain;
    border-radius: 20px !important;
}

.chatbot-expolre-border-bottom {
    border-bottom: 5px solid;
    border-image: linear-gradient(to right, #3b78ce 33%, #e8e021 66%);
    border-image-slice: 1;
    margin-top: 60px;
}

#chatbotnewScrollTitleLastWord {
    color: #333;
    font-weight: 500;
    font-size: 45px;
    line-height: normal;
    margin-top: 15px;
    margin-left: 15px;
}

.chatbot-small-title {
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 3.78px;
    text-transform: uppercase;
    background: -webkit-linear-gradient(90deg, #6a097d 50%, #0a6add 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-bottom: 25px;
}
.chatbot-newscrollelement-main-text {
    /* height: calc(100vh - 80px); */
    color: black;
    width: 50%;
    align-items: start;
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    /* margin-top: 400px; */
}
.chatbot-tempDiv {
    display: none;
    height: 170px;
    width: 50%;
}
.newscrollelement-main-div {
    border-bottom: 5px solid;
    border-image: linear-gradient(to right, #3b78ce 33%, #e8e021 66%);
    border-image-slice: 1;
}
.chatbot-newscrollelement-main-div-inner {
    width: 100%;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    column-gap: 100px;
    /* flex-direction: column; */
}
.cb-explore-full-heigth-wrapper {
    height: calc(100vh - 80px);
    /* border: 2px solid; */
    display: flex;
    align-items: center;
}
#chatbotnewScrollTitle {
    color: #333;
    font-weight: 500;
    font-size: 45px;
    line-height: normal;
    margin-top: 10px;
}

@media only screen and (min-width: 1900px) {
    #chatbotnewScrollTitle,
    #chatbotnewScrollTitleLastWord {
        font-size: 40px;
    }
    .cb-explore-heading {
        font-size: 40px;
    }
    .cb-explore-sub-heading {
        font-size: 21px;
    }
    #chatbotnewScrollDescription {
        font-size: 21px;
        font-weight: 400;
    }
}
@media only screen and (min-width: 1200px) and (max-width: 1900px) {
    .cb-explore-heading {
        font-size: 36px;
    }
    .cb-explore-sub-heading {
        font-size: 21px;
    }
    #chatbotnewScrollTitle,
    #chatbotnewScrollTitleLastWord {
        font-size: 36px;
    }
    #chatbotnewScrollDescription {
        font-size: 21px;
        font-weight: 400;
    }
}

/* @media screen and (max-width: 1500px) {
    #chatbotnewScrollTitleLastWord {
        font-size: 40px;
    }
} */

/* @media screen and (max-width: 1300px) {
    #chatbotnewScrollTitleLastWord {
        font-size: 35px;
    }
} */
