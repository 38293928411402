.writer-wiz-appbar {
    background-color: white !important;
}

.writer-wiz-appbar-onscroll {
    background-color: #6A097D !important
}


.wz-header {
    position: fixed !important;
    z-index: 1 !important;
    transition: .3s ease-in !important;
    overflow: hidden !important;
    position: fixed !important;
    box-shadow: none !important;
    background-color: #fff !important;
}


.wz-nav-menu-btn {
    font-size: 15px !important;
}

.wz-nav-menu-add-browser-btn,
.wz-nav-menu-add-browser-btn-transition {
    font-size: 15px !important;
}

.wz-website-head {
    color: #6A097D;
    font-size: 20px;
    font-weight: 700;
}

.wz-nav-menu-btn {
    font-size: 15px;
    font-style: normal;
    font-weight: 600 !important;
    color: black !important;
    text-transform: capitalize !important;
}

.wz-nav-menu-btn:hover {
    background-color: #F9EDFF !important;
}

.wz-header-toolbar .css-i6bazn {
    overflow: visible !important;
}

.wz-nav-menu-add-browser-btn:hover {
    background-color: #F9EDFF !important;
}

.wz-nav-menu-add-browser-btn {
    font-size: 15px;
    font-weight: 600 !important;
    text-transform: none !important;
    /* background-color: #6A097D !important; */
    color: #6A097D !important;
    background: #FFF;
    /* margin-right: 60px !important; */
    box-shadow: 0px 0px 2px 0px #6A097D;
    border-radius: 5px;
}

.wz-nav-menu-add-browser-btn-transition {
    font-size: 15px;
    text-transform: none !important;
    background-color: white !important;
    color: #6A097D !important;
    font-weight: 600 !important;
    border-radius: 5px;
    box-shadow: 0px 0px 2px 0px #6A097D !important;

}

.wz-header-scroll {
    background-color: white !important;
    position: fixed !important;
    z-index: 100 !important;
    transition: .3s ease-in !important;
    overflow: hidden !important;
    position: fixed !important;
    height: 75px !important;
    box-shadow: 0px -31px 20px 20px #111 !important;
}

.wz-header-logo-text-before-scroll {
    color: #1976d2 !important;
}

.wz-header-logo-text-after-scroll {
    color: white !important;
}

.wz-header-btn-text-before-scroll {
    color: #1976d2 !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;
}

.wz-header-btn-text-after-scroll {
    color: white !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;
}

.wz-btn-visible {
    transform: translate3d(168px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg) !important;
    transform-style: preserve-3d !important;
    opacity: 0 !important;

}

.wz-free-transition-btn-hidden {
    opacity: 0;
    margin-left: 0px;
}

.wz-free-transition-btn {
    border-radius: 5px !important;
    background: #6A097D !important;
    text-transform: capitalize !important;
    font-size: 15px !important;
    margin-left: 15px !important;
    /* width: 170px; */
    /* transform: translateX(50) ; */
    font-weight: 500 !important;
    animation: buttonEntry 0.5s;
}

.wz-signup-btn-without-transition {
    border-radius: 5px !important;
    background: #6A097D !important;
    text-transform: capitalize !important;
    font-size: 15px !important;
    margin-left: 15px !important;
    font-weight: 500 !important;
}

.navbar-computer-right-button-group {
    animation: moveButtonGroup 0.5s ease-in-out;
}

.navbar-computer-right-button-group-with-hidden-button {
    transform: translate(210px, 0px);

    /* transform: translate(115px, 0px); */
    animation: moveButtonGroupReverse 0.5s ease-in-out;
}

.navbar-contact-us:hover {
    background-color: #6A097D !important;
}

@media (max-width: 818px) {
    .wz-nav-menu-btn {
        font-size: 14px !important;
    }
}

@keyframes buttonEntry {
    0% {
        transform: translateX(0px);
        /* width: 0px; */
    }

    50% {
        transform: translateX(90px);
    }

    100% {
        transform: translateX(0px);
        /* width: auto */
    }
}

@keyframes moveButtonGroup {
    0% {
        transform: translate(210px, 0px);

        /* width: 0px; */
    }

    100% {
        transform: translate(0px, 0px);

        /* width: auto */
    }
}

@keyframes moveButtonGroupReverse {
    0% {
        transform: translate(0px, 0px);
        /* width: auto */
    }

    100% {
        transform: translate(210px, 0px);
        /* width: 0px; */
    }


}

.mobileHamburgerSideBar {
    background-color: #6A097D !important;
}

.mobileHamburgerSideBar-logo-container {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin-inline: auto;
}

.mobileHamburgerSideBar-button {
    background-color: white;
    margin: 10px;
    width: 70%;
    color: black;
    border-radius: 5px;
    padding: 15px;
}

.mobileHamburgerSideBar-inner-div {
    width: 100%;
}

.mobileHamburgerSideBar-center-box {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.mobileHamburgerSideBar-back-button {
    border-radius: 50%;
    height: 40px;
    position: fixed !important;
    right: 10px;
    border: none;
    outline: none;
    box-shadow: none !important;
    background-color: transparent !important;
}

.mobileHamburgerSideBar-footer-parent {
    margin-top: 310px;
    width: 90%;
    margin-inline: auto;
    color: white;
    display: flex;
    text-align: left;
}

.mobileHamburgerSideBar-footer-item {
    color: white
}

.mobileHamburgerSideBar-footer-left {
    width: 50%;
    margin-left: 40px;
}

.mobileHamburgerSideBar-footer-right {
    width: 50%;
    margin-left: 50px;

}

.mobileHamburgerSideBar-parent-div {
    display: flex;
    justify-content: space-between;
}

.navbar-drawer-button {
    display: none !important;
    margin: 0px !important;
}

.wz-header-toolbar {
    height: 100%;
}

.iframe-dialog .MuiDialog-paperScrollPaper {
    width: 100% !important;
}

@media screen and (max-width: 900px) {

    .navbar-computer-right-button-group-with-hidden-button,
    .navbar-computer-right-button-group-without-transition {
        display: none;
    }

    .navbar-computer-right-button-group {
        display: none;
    }

    .navbar-drawer-button {
        display: flex !important;
    }

    .wz-header-toolbar {
        width: 97% !important;
    }
}

@media screen and (max-width: 500px) {
    .wz-header {
        padding: 0px !important;
        justify-content: space-between;
        align-items: flex-end;
    }

    .mobileHamburgerSideBar-footer-parent {
        margin-top: 300px;
        width: 100%;
    }

}

@media (max-width: 430px) {
    .wz-mainlogo-container img {
        width: 76%;
    }
}