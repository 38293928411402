.grp-wz-logo {
    width: 180px;
    margin: 10px 0 20px 0;
}

.grp-back-to-home-btn {
    border-radius: 5px !important;
    background: #6A097D !important;
    text-transform: capitalize !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    padding: 7px 20px !important;
    margin: 20px 0 10px 0!important;
    cursor: pointer !important;
}

.grp-popup-text {
    font-size: 18px !important;
    font-weight: 500 !important;
    margin: 8px 10px !important; 
}
  
@media (max-width: 380px) {
    .grp-popup-text {
        font-size: 17px !important;
    }
}