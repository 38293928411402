.usecasemobile-main-div {
  background-color: #F9EDFF;
  padding: 30px 0 50px 0;
}

.usecasemobile-inner-div {
  /* height: 100vh; */
  margin-top: 40px;
  display: flex;
  align-items: center;
}

.usecasemobile-main-text {
  color: black;
  width: 85%;
  align-items: center;
}

#usecasemobileText {
  margin-top: 10px;
}

.usecasemobile-main-text-inner-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  column-gap: 10px;
}

.usecasemobile-main-image {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 65px;
  width: 85%;
}

.usecasemobile-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.usecasemobileSmallTitle {
  font-weight: 700;
  font-size: 18px;
  background: linear-gradient(180deg, #6A097D 0%, #0A6ADD 100%);
  ;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.usecasemobile-main-image-inner-div {
  width: 90%;
}

.usecasemobileTitle {
  font-size: 25px;
  color: black;
  font-weight: 600;
  line-height: normal;
  width: 80%;
  margin-top: 15px;
}

.usecasemobileDescription {
  font-weight: 400;
  color: #7A7A7A;
  font-size: 15px;
  margin-top: 20px;
}

.CarouselStyles .slide {
  display: flex !important;
  justify-content: end !important;
}

.carousel-main-title-text {
  color: #000;
  font-size: 25px;
  font-weight: 600;
  line-height: normal;
  margin-top: 15px;
}

@media screen and (min-width: 800px) and (max-width: 1000px) {
  .usecasemobile-main-image-inner-div {
    width: 60%;
  }

  .usecasemobileTitle {
    width: 100%;
  }

}

@media screen and (max-width: 599px) {
  .usecasemobile-main-image-inner-div {
    width: 90%;
  }

  .usecasemobileTitle {
    font-size: 18px;
  }

  .usecasemobileDescription {
    font-size: 13px;
  }

  .usecasemobileSmallTitle {
    font-weight: 600;
    font-size: 13px;
  }

  .usecasemobile-main-div {
    padding: 0px 0 50px 0;
  }

  .usecasemobileTitle {
    width: 100%;
  }

  .usecasemobile-main-text {
    color: black;
    width: 90%;
    align-items: center;
  }

  .carousel-main-title-text {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    margin-top: 15px;
  }

}