.ap-table-main-container {
  background: rgba(247, 233, 255, 0.50);
  padding: 60px 0px 60px !important;
}

.ap-table-head-item {
  margin: 0 auto !important;
}

.ap-table-item {
  margin: 80px auto 0px !important;
}

.table-header-text {
  font-size: 48px;
  margin: 0px 0 10px 0;
  font-weight: 600;
}

.table-header-paratext {
  color: #7A7A7A;
  font-size: 18px;
  margin-bottom: 96px;
}

.highlighted-text-1 {
  color: #6A097D;
}

.highlighted-text-2 {
  color: #2B61A2;
}

.ap-comparison-table-head-text1 {
  color: #6A097D !important;
  font-size: 40px !important;
  font-weight: 600 !important;
}

.ap-comparison-table-head-text3 {
  color: #2B61A2 !important;
  font-size: 40px !important;
  font-weight: 600 !important;
}

.ap-comparison-table-head-text2 {
  color: black !important;
  font-size: 40px !important;
  font-weight: 600 !important;
}

.comparison-table-main {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
}

.comparison-table-head {
  margin-block-start: 60px;
  margin-block-end: 50px;
}

.comparison-table-head th {
  font-size: 30px;
  font-weight: 600;
  padding-top: 60px;
  text-align: center;
}

.comparison-table-head-row th:nth-child(2),
.comparison-table-body-row td:nth-child(2) {
  border-left: 0.5px solid #B3B3B3;
  border-right: 0.5px solid #B3B3B3;
  /* box-shadow: -4px 0 5px -5px rgba(0, 0, 0, 0.25), 5px 0 5px -5px rgba(0, 0, 0, 0.25); */
}

.comparison-table-head-row th:nth-child(3),
.comparison-table-body-row td:nth-child(3) {
  border-right: 0.5px solid #B3B3B3;
  /* box-shadow: -4px 0 5px -5px rgba(0, 0, 0, 0.25), 5px 0 5px -5px rgba(0, 0, 0, 0.25); */
  margin-left: 50px;

}

.comparison-table-head-row td:first-child {
  width: 53%;
  /* padding: 0 20px; */
}

.comparison-table-head-row th:nth-child(2),
.comparison-table-head-row th:nth-child(3) {
  width: 23.5%;
}

.comparison-table-head-row th:nth-child(4) {
  width: 7.5%;
}

.comparison-table-head-writewiz-column {
  background-color: #FDF9FF;
  position: relative;
  text-align: center;
}

.comparison-table-body-col1-text {
  color: #000;
  font-weight: 500;
  font-size: 16px;
  margin: 20px 0 0 0;

}

.comparison-table-body-col1-subtext {
  color: #717171;
  font-size: 13px;
  margin: 5px 0 0 0;
}

.comparison-table-body-writewiz-column {
  text-align: center;
  background-color: #FDF9FF;
}

.comparison-table-body-writer-column {
  text-align: center;
}

.comparison-table-body-col1-header {
  color: #6A097D;
  font-size: 24px;
  font-weight: 600;
  padding-block-start: 40px;
}

.comparison-table-body-col1 {
  padding-inline-start: 77px;
}

.comparison-table-head-writewiz-column-topchoice {
  color: #FFF;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
  background-color: #6A097D;
  height: 46px;
  border-radius: 10px 10px 0 0;

  display: flex;
  justify-content: center;
  align-items: center;
  /* Positioning the element */
  /* margin-bottom: 46px; */

}

.comparison-table-head-writewiz-column-content {
  position: absolute;
  height: 46px;
  top: -54px;
  left: 0;
  right: 0;
  background-color: #6A097D;
  color: #fff;
  padding: 5px 10px;
  border-radius: 10px 10px 0 0;
  z-index: 2;
}

.comparison-table-head-writewiz-column img {
  display: block;
  max-width: 100%;
  margin: 0 auto;
  z-index: 1;

}

.comparison-table-body-lastrow {
  padding-bottom: 48px;
}

.ap-comparison-table-subtitle-text {
  font-size: 16px !important;
  font-weight: 500 !important;
}


@media screen and (max-width: 700px) {
  .comparison-table-main {
    display: block;
  }

  comparison-table-head-row th,
  comparison-table-body-row td {
    display: block;
    width: 100%;
  }
}

@media only screen and (min-width: 320px) and (max-width: 599px) {

  .ap-comparison-table-head-text1 {
    font-size: 24px !important;
    font-weight: 600 !important;
  }

  .ap-comparison-table-head-text2 {
    font-size: 24px !important;
    font-weight: 600 !important;
  }

  .ap-comparison-table-head-text3 {
    font-size: 24px !important;
    font-weight: 600 !important;
  }

  .ap-comparison-table-subtitle-text {
    font-size: 14px !important;
    font-weight: 500 !important;
  }

}

@media only screen and (min-width: 600px) and (max-width: 899px) {

  .ap-comparison-table-head-text1 {
    font-size: 34px !important;
    font-weight: 600 !important;
  }

  .ap-comparison-table-head-text2 {
    font-size: 34px !important;
    font-weight: 600 !important;
  }

  .ap-comparison-table-head-text3 {
    font-size: 34px !important;
    font-weight: 600 !important;
  }

  .ap-comparison-table-subtitle-text {
    font-size: 15px !important;
    font-weight: 500 !important;
  }

}