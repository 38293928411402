.CB-chatBotFeatures {
  margin-top: 100px !important;
  margin-bottom: 160px !important;
}

.CB-chatBotStats {
  margin-bottom: 90px !important;
}

.chatbot-faq-section {
  background: linear-gradient(
    180deg,
    rgba(247, 233, 255, 1) 44%,
    rgba(247, 233, 255, 1) 66%,
    rgba(247, 233, 255, 1) 87%,
    rgba(255, 255, 255, 1) 100%
  );
  height: auto;
  padding-bottom: 100px !important;
  margin: 60px 0px 120px !important;
}
@media only screen and (min-width: 650px) and (max-width: 1200px) {
  .CB-chatBotStats {
    margin: 60px auto 35px !important;
  }
}
@media only screen and (min-width: 0px) and (max-width: 650px) {
  .CB-chatBotStats {
    margin-bottom: 35px !important;
  }
}
