/* .tablepage-container {
    background: rgba(247, 233, 255, 0.50);
} */

.ap-page-intro-item {
    background-color: #F7E9FF80 !important;
    padding: 20px 0px !important;
}

.ap-page-bottom-margin {
    margin-bottom: 100px !important;
}