.cw-mark-img {
    vertical-align: middle;
}

.cw-grid-dt-text {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 40px !important;
}

.cw-grid-dd-text {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 25px !important;
}

.cw-point-head-text {
    margin-bottom: 30PX !important;
    /* font-size: 33px !important;
    font-weight: 500 !important; */
}

.cw-feature-sub-container {
    justify-content: space-between;
    align-items: center;
    margin: 80px auto !important;
}