.fb-form-main-container {
    background-color: #FDF9FF !important;
    min-height: 100vh !important;
    height: 100% !important;
    background-image: url('../../assests/FeedbackWaes.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.fb-form-main-item {
    margin: 100px 0px 30px !important;
}

.fb-form-white-item {
    background-color: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    margin: 0 auto !important;
    border-radius: 2px;
    padding: 40px !important;
}

.fb-form-white-sub-item {
    margin: 0 auto !important;
    text-align: center;
}

.fb-form-main-head-text {
    color: #6A097D !important;
    font-size: 24px !important;
    font-weight: 600 !important;
}

.fb-form-sub-head-text {
    color: black;
    font-size: 18px;
    font-weight: 500;
}

.fb-form-highlighted-text {
    color: #6A097D !important;
    font-size: 18px !important;
    font-weight: 600 !important;
}

.fb-form-common-item {
    margin: 10px auto !important;
}

.fb-form-input-sub-head-text {
    color: black;
    font-size: 15px;
    font-weight: 600;
}

.fb-form-input-highlighted-text {
    color: #6A097D !important;
    font-size: 15px !important;
    font-weight: 600 !important;
}

.fb-checkbox-label .MuiTypography-root {
    font-size: 15px !important;
    font-weight: 500 !important;
}

.fb-form-textarea {
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    outline: none;
    border: none;
    width: 100%;
    font-size: 15px;
    padding: 7px 13px
}

.fb-form-textarea:focus {
    border: 1px solid #6A097D;
}

@media screen and (min-width : 320px) and (max-width : 600px) {
    .fb-form-white-item {
        padding: 20px !important;
    }

    .fb-form-main-item {
        margin: 80px 0px 20px !important;
        height: 100% !important;
    }

}