/* .wp-main-container {} */

/* navbar */
.wp-tool-navbar {
    padding-left: 50px !important;
    padding-right: 50px !important;
    margin-top: 15px;
    align-items: flex-end !important;
}

/* navbar */

.wp-main-item {
    /* min-height: 78vh !important; */
    /* max-height: 100vh !important; */
    /* height: auto !important; */
    background-image: url('../../assests/WelcomePageBg.png');
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
    min-height: 100vh !important;
    max-height: 100vh !important;
}

.wp-sub-container {
    justify-content: center !important;
    padding: 60px !important;
    max-height: 100vh !important;
    overflow: auto !important;
}




.wp-sub-item {
    background-color: white;
    border-radius: 5px !important;
    height: auto !important;
    padding: 5px 0px 0px 0px !important;
    /* margin: 60px auto !important; */
    /* min-height: 90vh !important; */
}


.wp-sub-container {
    height: 100%;
    overflow-y: auto;
    padding: 54px !important;
}


.wp-onbarding-sub-container {
    height: auto !important;
    max-height: auto;
    margin-top: 0px;
}


.wp-logo-item {
    margin: 100px auto 50px !important;
    text-align: center !important;
}

.wp-extention-link-item {
    margin: 0 auto !important;
    text-align: center !important;
    font-size: 17px !important;
    font-weight: 500 !important;
    color: #888 !important;
    min-height: 25px !important;
    max-height: 25px !important;
}

.wp-extention-link-item2 {
    margin: 12px 0px !important;
}

.wp-ext-input-ht-item {
    margin: 0px auto 20px !important;
}

.wp-render-component-item {
    margin: 0px auto 10px !important;
}

.wp-highlighted-text {
    color: #6A097D !important;
    font-size: 26px !important;
    font-weight: 550 !important;
}

.wp-normal-text {
    color: black !important;
    font-size: 26px !important;
    font-weight: 600 !important;
}

.wp-spelling-underline-text {
    color: black !important;
    font-size: 26px !important;
    font-weight: 600 !important;
    text-decoration: underline #CC2424 !important;
}

.wp-bg-highlight-text {
    background-color: #F7E9FF;
}

.wp-normal-small-text {
    color: black !important;
    font-size: 15px !important;
    font-weight: 500 !important;
}

.wp-highlighted-small-text {
    color: #6A097D !important;
    font-size: 15px !important;
    font-weight: 500 !important;
}


.wp-ext-textarea {
    border-radius: 10px !important;
    padding: 26px 24px !important;
    font-size: 17px !important;
    font-weight: 500 !important;
}

.wp-ext-textarea:focus {
    outline: none;
    box-shadow: none;
    border-color: initial;
}

.wp-ext-next-back-btn-item {
    margin: 15px auto !important;
}

.wp-ext-next-back-btn-container {
    justify-content: space-between;
}

.wp-ext-back-btn {
    color: #6A097D !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    justify-content: flex-start !important;
    text-transform: capitalize !important;
}

.wp-ext-next-btn {
    background-color: #6A097D !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;

}

/* step1 */
.wp-step1-text-container {
    justify-content: center;
    position: relative;
}

.wp-step1-arrow-img-item {
    text-align: end;
    position: absolute;
    left: 98%;
    top: 10%;
}

.wp-step2-text-container {
    justify-content: start;
}

.wp-ext-step4-main-head {
    text-align: center !important;
    margin-bottom: 10px !important;
}

.wp-ext-step4-sub-head {
    text-align: center !important;
    margin-top: 10px !important;
    margin-bottom: 20px !important;
}

.wp-highlighted-link-text {
    color: #6A097D !important;
    font-size: 15px !important;
    font-weight: 500 !important;
}

.wp-ext-iframe {
    border-radius: 10px !important;
    width: 700px !important;
    height: 400px !important;
}

.wp-gb-sentences-item {
    display: flex;
    min-height: 60px !important;
    height: 60px !important;
    margin-bottom: 25px !important;
    align-items: center !important;
}

.wp-gb-center-sentences-item {
    text-align: center !important;
}




@media only screen and (min-width: 1536px) and (max-width: 1700) {

    .wp-ext-iframe {
        border-radius: 10px !important;
        width: 1200px !important;
        height: 1000px !important;
    }
}

@media only screen and (min-width: 900px) and (max-width: 1310px) {

    .wp-gb-sentences-item {
        display: flex;
        min-height: 75px !important;
        height: 75px !important;
        margin-bottom: 15px !important;
        align-items: center !important;
    }

}



/* step1 */

/* media */

.wp-ext-media-main-container {
    justify-content: space-between;
}

.wp-ext-media-item {
    border-radius: 1px 10px;
    background: #FFF;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: 1px solid rgba(101, 101, 101, 0.25);
    padding: 10px 20px;
    margin: 15px 0px !important;
}

.wz-editor-card {
    padding: 10px 5px !important;
}

.wp-ext-img-item,
.wp-ext-media-label-item {
    text-align: center !important;
    margin: 5px 0px !important;
}

.wp-ext-ussage-wz-item {
    border-radius: 0px 0px 10px 10px;
    background-image: url('../../assests//wzEditorCardBg1.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 25px 10px 25px;
    border-bottom-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top: 2px solid #6A097D;
    /* background: linear-gradient(134deg, #F1D9FF 0%, rgba(241, 217, 255, 0.00) 100%); */
}

.wp-ext-use-head-item {
    text-align: center;
    margin-bottom: 40px;
    margin-top: 5px !important;
}

.wz-editor-logo {
    margin: 20px !important;
}

.wz-editor-sub-item {
    margin: 30px auto 30px !important;
}

.wz-editor-container {
    justify-content: space-between;
}


.wp-ext-cb-et-btn {
    text-transform: capitalize !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    color: #6A097D !important;
    border: 1px solid #6A097D !important;
    border-radius: 5px !important;
}

.step3-card-media-iframe {
    min-height: 300px !important;
    max-height: 300px !important;
    width: 100% !important;
}

.wp-steps-textarea-gb-item {
    min-height: 300px !important;
    max-height: 300px !important;
}



/* media */

/* navbar */
.wp-navbar-item {
    margin-bottom: 25px !important;
}

.wp-navbar {
    background-color: white !important;
    box-shadow: none !important;
}

/* navbar */

/* dialog */
.wp-ext-dialog-item {
    margin: 10px auto 0px !important;
}

.wp-ext-dialog-main-item {
    padding: 20px 15px !important;
}

.wp-ext-caption-item {
    margin: 10px auto 10px !important;
    text-align: center !important;
}

.wp-ext-caption-text {
    color: #7A7A7A !important;
    font-size: 15px !important;
    font-weight: 400 !important;
}

.wp-ext-caption-highlight-text {
    color: #6A097D !important;
    font-size: 15px !important;
    font-weight: 500 !important;
}

.wp-ext-caption2-item {
    margin: 40px auto 10px !important;
    text-align: center !important;
}

.wp-ext-caption2-text {
    color: black !important;
    font-size: 18px !important;
    font-weight: 400 !important;
}

.wp-ext-caption2-highlight-text {
    color: #6A097D !important;
    font-size: 18px !important;
    font-weight: 600 !important;
}

.wp-ext-signup-btn-item {
    margin: 10px auto !important;
}

.wp-ext-signup-btn {
    background-color: #6A097D !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: white !important;
    text-transform: capitalize !important;
    border-radius: 10px !important;
}

.wp-ext-caption3-text-item {
    margin: 10px auto !important;
    text-align: center !important;
}

.wp-ext-caption3-text {
    color: black !important;
    font-size: 14px !important;
    font-weight: 400 !important;
}

.wp-ext-caption3-highlight-text {
    color: #6A097D !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    padding: 0px !important;
    text-transform: capitalize !important;
    letter-spacing: inherit !important;
    line-height: inherit !important;
    justify-content: start !important;
    min-width: unset !important;
    padding-bottom:2px !important;
}

.wp-ext-skip-btn {
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #6A097D !important;
    text-transform: capitalize !important;
    border-radius: 10px !important;
    padding: 0 !important;
}

.wp-signup-normal-text {
    color: black !important;
    font-size: 14px !important;
    font-weight: 600 !important;
}


.wp-signup-highlight-text {
    color: #6A097D !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    text-transform: none !important;
    align-items: center !important;
}

.wp-ext-caption2-subtitle-text-item {
    margin: 0 auto !important;
    text-align: center !important;
}

.wp-ext-caption2-subtitle-text {
    color: #7A7A7A;
    font-size: 18px;
    font-weight: 400;
}

/* dialog */