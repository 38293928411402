#EnterpriseSection-right-image {
  border-radius: 15px;
}

.EnterpriseSection-main-div {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 90vh;
  padding: 70px 0;
}
.EnterpriseSection-inner-div {
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  width: 80%;
}
.EnterpriseSection-text-title {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.EnterpriseSection-below-main-section {
  display: flex;
  justify-content: center;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 80px;
}
.EnterpriseSection-left-side-section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 50%;
}
.EnterpriseSection-text-title-upper-text {
  color: #6a097d;
  font-size: 18px;
  font-weight: 600;
  font-weight: 600;
  line-height: 100%; /* 18px */
  letter-spacing: 3.78px;
}
.EnterpriseSection-text-title-below-text {
  color: #333;
  font-size: 30px;
  margin-top: 20px;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}
.EnterpriseSection-button-numbers {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  background: #F7E9FF;
}
.EnterpriseSection-left-side-section-one {
  color: #6a097d;
  font-size: 18px;
  font-weight: 600;
}

.EnterpriseSection-left-side-section-title {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  margin-top: 10px;
  line-height: 160%; /* 32px */
}
.EnterpriseSection-left-side-section-name {
  color: #333;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.EnterpriseSection-left-side-section-description {
  color: #000;
  font-size: 15px;
  font-weight: 400;
  margin-top: 25px;
  line-height: 160%; /* 24px */
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  
}
.EnterpriseSection-left-side-section-buttons {
  margin-top: 150px;
}
.EnterpriseSection-left-side-section-description-subtitle{
  color: #7A7A7A;
font-family: Open Sans;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.EnterpriseSection-left-side-section-description-title{
  color: #000;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: 160%; /* 24px */
}
.EnterpriseSection-right-side-section-image-div {
  width: 610px;
  height: 630px;
  flex-shrink: 0;
  border-radius: 20px;
  background: transparent;
}
.EnterpriseSection-right-side-section {
  display: flex;
  justify-content: center;
  align-items: center;
}
.EnterpriseSection-button {
  /* min-width: 125px; */
  width: 300px;
  height: 40px;
  flex-shrink: 0;
  padding: 10px;
  padding-right: 20px;
  border-radius: 10px;
  background: #fff;
  color: #7a7a7a;
  column-gap: 10px;
  border: none;
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
  margin: 5px 10px 5px 0;
  font-weight: 600;
  cursor: pointer;
  min-width: 40%;
}
.EnterpriseSection-button:hover {
  /* outline: 1px solid grey; */
}
.EnterpriseSection-button-selected {
  box-shadow: 0px 0px 2px 0px #6a097d;
  color: #6a097d;
  /* color:white; */
}
.enterprise-selected-data {
  animation: fadeIn 1s ease-in-out;
}

.EnterpriseSection-button-selected .EnterpriseSection-button-numbers {
  width: 28px;
  height: 28px;
  background-color: #6a097d;
  border-radius: 50%;
  color: white;
  display: flex;
  outline: none;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}

.EnterpriseSection-top-side-buttons,
.EnterpriseSection-bottom-side-buttons {
  display: flex;
  flex-direction: row;
  justify-content: start;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


@media (max-width: 1800px) {
  .EnterpriseSection-left-side-section{
    width: 50%;
  }
}

@media (max-width: 1600px) {
  .EnterpriseSection-right-side-section-image-div {
    width: 550px;
    height: 575px;
  }
  .EnterpriseSection-left-side-section-buttons {
    margin-top: 100px;
  }
}

@media (max-width: 1500px) {
  .EnterpriseSection-top-side-buttons, .EnterpriseSection-bottom-side-buttons{
    flex-direction: column;
  }
}

@media (max-width: 1300px) {
  .EnterpriseSection-right-side-section-image-div {
    width: 450px;
    height: 480px;
    margin-left: 20px;
  }
}

/* Media Query for 1200px and above */
@media (max-width: 1200px) {
  .EnterpriseSection-inner-div {
    width: 90%;
  }

  .EnterpriseSection-left-side-section-name {
    font-size: 45px;
  }
  /* .EnterpriseSection-right-side-section-image-div {
    width: 400px;
    height: 400px;
  } */
  .EnterpriseSection-text-title-below-text {
    font-size: 25px;
  }
}
@media (max-width: 900px) {
  .EnterpriseSection-left-side-section-title {
    font-size: 22px;
  }

  .EnterpriseSection-left-side-section-name {
    font-size: 35px;
  }
  .EnterpriseSection-left-side-section-buttons {
    margin-top: 30px;
  }
  .EnterpriseSection-right-side-section-image-div {
    width: 350px;
    height: 350px;
  }
  .EnterpriseSection-text-title-below-text {
    font-size: 25px;
  }
  .EnterpriseSection-main-div {
    margin-top: 100px;
    padding: 0 0 50px 0;
  }
  .EnterpriseSection-top-side-buttons,
  .EnterpriseSection-bottom-side-buttons {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 800px) {
  .EnterpriseSection-below-main-section {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .EnterpriseSection-left-side-section {
    width: 70%;
    margin-top: 50px;
    justify-content: center;
    align-items: start;
    /* text-align: justify; */
  }
  .EnterpriseSection-text-title-upper-text {
    font-size: 15px;
  }
  .EnterpriseSection-right-side-section-image-div {
    width: 350px;
    height: 370px;
    max-width: 90%;
    margin: 0 auto;
  }
  .EnterpriseSection-below-main-section {
    margin-top: 40px;
  }
  .EnterpriseSection-left-side-section-buttons {
    margin: 35px auto;
  }
  .EnterpriseSection-left-side-section-buttons {
    /* display: flex; */
  }
  .EnterpriseSection-main-div {
    margin-top: 60px;
  }
  .EnterpriseSection-left-side-section-name {
    font-size: 35px;
  }
  .EnterpriseSection-left-side-section-title {
    font-size: 20px;
  }
}
@media (max-width: 650px) {
  .EnterpriseSection-left-side-section-title {
    font-size: 20px;
  }

  .EnterpriseSection-left-side-section-name {
    font-size: 30px;
  }
  .EnterpriseSection-left-side-section-buttons {
    /* display: flex; */
    flex-direction: column;
  }
  .EnterpriseSection-left-side-section-buttons {
    margin: 35px auto 10px auto;
  }
  .EnterpriseSection-top-side-buttons,
  .EnterpriseSection-bottom-side-buttons {
    display: flex;
    flex-direction: column;
  }
  .EnterpriseSection-left-side-section {
    width: 80%;
    margin-top: 50px;
    justify-content: center;
    align-items: start;
    /* text-align: justify; */
  }
}
