@media (max-width: 500px) {
    .lp-recaptcha-popup {
        scale: 0.9;
        transform-origin: left;
    }
}

@media (max-width: 400px) {
    .lp-recaptcha-popup {
        scale: 0.85;
        transform-origin: left;
    }
}