/* ------------------------------------------Pricing------------------------------------------ */

.pp-main-item {
    margin: 110px auto 0px !important;
}

.pp-title-item {
    margin: 0 auto !important;
    text-align: center !important;
}

.pp-main-title {
    font-weight: 600 !important;
}

.pp-main-color-title {
    color: #6A097D;
    font-weight: 600 !important;
}

.pp-sub-title-item {
    text-align: center !important;
    margin: 10px 0px !important;
}

.pp-monthly-annually-item {
    margin: 40px auto !important;
}

.pp-monthly-annually-container {
    justify-content: center;
}

.mask-box {
    position: relative;
    background: rgba(247, 233, 255, 0.50) !important;
    color: black;
    padding: 7px 10px 5px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    display: flex !important;
}

.mask-box .mask {
    width: 150px;
    height: 45px;
    background: #6A097D;
    position: absolute;
    /* box-shadow: 1px 0 2px white; */
    transition: all 0.5s ease;
    color: white !important;
    border-radius: 5px;
}

.mask-box .MuiButton-root {
    width: 150px;
    transition: all 0.2s 0.1s ease;
    color: black;
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
}

.mask-box .selected-monthly-button {
    width: 150px;
    background: #6A097D;
    color: white;
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
}

.mask-box .selected-yearly-button {
    width: 150px;
    background: #6A097D;
    color: white;
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
}

.pp-plans-panel-main-item {
    margin: 0 auto !important;
}

.pp-plan-feature-item {
    margin: 0px auto 0px !important;
}

/* ------------------------------------------Pricing------------------------------------------ */


/* ------------------------------------------Pricing Card--------------------------------------------- */

.pp-plans-bg-main-item {
    background: #FDF9FF !important;
    margin: 100px 0px 0px !important;
    padding: 80px 0px !important;
}

.pp-plans-panel-main-container {
    display: grid;
    gap: 65px;
    justify-content: center;
    /* due to dynamic */
    /* justify-content: space-between; */
}

.pp-plans-panel-sub-item {
    background-color: #fdfdfd !important;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
    border-radius: 10px !important;
    min-height: 662px !important;
}

.pp-plans-panel-sub-item:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px !important;
}



.pp-recommended-label {
    position: absolute;
    background-color: #6A097D;
    color: white;
    padding: 4px 8px;
    border-radius: 0 0 0 8px;
    font-size: 12px;
    z-index: 1;
    position: relative;
}

.pp-plan-card-item1 {
    margin: 20px auto 0px !important;
    padding: 5px !important;
}

/* .pp-plan-card-item2 {
    margin: 0px auto !important;
    padding: 5px !important;
    transition: all 1s !important;
    visibility: hidden !important;
} */

.pp-plan-card-item2 {
    margin: 0px auto !important;
    padding: 5px !important;
}



.pp-plan-subtitle-item {
    /* min-height: 8vh !important;
    max-height: 8vh !important; */
    margin-bottom: 20px !important;
}

.pp-plan-package-label-title {
    font-size: 22px !important;
    font-weight: 500 !important;
}

.pp-plan-package-label-title-premium {
    font-size: 22px !important;
    font-weight: 500 !important;
    color: #6A097D;
}

.pp-plan-package-label-sub-title {
    font-size: 15px !important;
    font-weight: 500 !important;
    color: #7A7A7A;
}

.pp-plan-package-label-sub-title-premium {
    font-size: 15px !important;
    font-weight: 500 !important;
}

.pp-plan-package-amount-label-item {
    margin: 0px 0px 5px 0px !important;
}

.pp-plan-amount-discount-container {
    justify-content: space-between;
    align-items: center;
}

.pp-plan-package-amount-label {
    font-size: 25px !important;
    font-weight: 500 !important;
}

.pp-plan-package-amount-month-label {
    font-size: 16px !important;
    font-weight: 500 !important;
}

.pp-free-plan-label-item {
    text-align: end !important;
}

.pp-free-plan-label {
    color: #6A097D;
    font-size: 15px !important;
    font-weight: 500 !important;
}

.pp-plan-amount-discount-item {
    text-align: end !important;
    border-left: 2px solid #1D944C !important;
}

.pp-plan-package-dis-amount-label-item {
    margin: 10px 0px 10px 0px !important;
}

.pp-plan-package-dis-amount-label-item-typo {
    color: #6A097D !important;
}

/* talk to sales button */
.pp-plan-more-user-item {
    margin: 10px 0px !important;
}

.pp-plan-talk-to-sales-div {
    display: flex;
    justify-content: start;
    align-items: center;
    background-color: white;
}

.pp-plan-talk-to-sales-btn-item {
    margin: 5px auto 10px !important;
}

.pp-plan-talk-to-sales-btn {
    width: 90%;
    padding: 10px;
    border: none;
    outline: none;
    position: relative;
    z-index: 1;
    border-radius: 5px;
    background: linear-gradient(180deg, #6A097D 0%, #0A6ADD 100%);
    cursor: pointer;
}

.pp-plan-talk-to-sales-btn::before {
    content: "";
    position: absolute;
    left: 1px;
    right: 1px;
    top: 1px;
    bottom: 1px;
    border-radius: 4px;
    background-color: white;
    z-index: -1;
    /* transition: 200ms */
}

.pp-plan-talk-to-sales-btn::after {
    content: attr(data);
    font-size: 15px;
    font-weight: 500;
    background: linear-gradient(180deg, #6A097D 0%, #0A6ADD 100%);
    -webkit-background-clip: text;
    color: transparent;
}

/* ---- */

.pp-plan-Premium-talk-to-sales-div {
    display: flex;
    justify-content: start;
    align-items: center;
    background-color: rgba(247, 233, 255, 0.50);
    ;
}

.pp-plan-talk-to-sales-btn-item {
    margin: 5px auto 10px !important;
}

.pp-plan-Premium-talk-to-sales-btn {
    width: 90%;
    padding: 10px;
    border: none;
    outline: none;
    position: relative;
    z-index: 1;
    border-radius: 5px;
    background: linear-gradient(180deg, #6A097D 0%, #0A6ADD 100%);
    cursor: pointer;
}

.pp-plan-Premium-talk-to-sales-btn::before {
    content: "";
    position: absolute;
    left: 1px;
    right: 1px;
    top: 1px;
    bottom: 1px;
    border-radius: 4px;
    background-color: #fbf4ff;
    z-index: -1;
    /* transition: 200ms */
}

.pp-plan-Premium-talk-to-sales-btn::after {
    content: attr(data);
    font-size: 15px;
    font-weight: 500;
    background: linear-gradient(180deg, #6A097D 0%, #0A6ADD 100%);
    -webkit-background-clip: text;
    color: transparent;
}

/* talk to sales button */


.pp-plan-package-dis-amount-label {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #1D944C !important;
}

.pp-plan-btn-item {
    margin: 10px 0px !important;
}

.pp-plan-btn {
    border: 1px solid #6A097D !important;
    text-transform: capitalize !important;
    color: white !important;
    border-radius: 5px !important;
    color: #6A097D !important;
}

.pp-plan-btn-selected {
    background-color: #6A097D !important;
    text-transform: capitalize !important;
    color: white !important;
    border-radius: 5px !important;
}

.pp-plan-check-icon {
    min-width: 35px !important;
}

.pp-info-check-icon {
    min-width: 30px !important;
    justify-content: end !important;
}

.pp-plans-points {
    color: black;
    font-size: 14px;
    font-weight: 300;
}

.pp-plan-highlighted-text {
    color: black;
    font-size: 14px;
    font-weight: 600;
}

.pp-plans-list-points {
    padding-left: 0 !important;
    padding-right: 0 !important;
    align-items: center !important;
}

.selected-item {
    box-shadow: 5px -5px 20px 0px rgba(16, 24, 40, 0.03), 0px 24px 48px -12px rgba(16, 24, 40, 0.18) !important;
    border-radius: 10px !important;
    background: rgba(247, 233, 255, 0.50) !important;
}

.pp-plan-slider-item {
    margin: 5px auto 10px !important;
}

.pp-plan-slider-conainer {
    flex-wrap: nowrap !important;
    overflow: auto;
    justify-content: space-between;
    gap: 34px;
}

.pp-plan-credit-btn-item {
    margin: 0px 10px 10px 0px !important;
}

.pp-plan-credit-btn-selected {
    background-color: #6A097D !important;
    color: white !important;
    text-transform: capitalize !important;
    font-size: 15px !important;
    font-weight: 500 !important;
}

.pp-plan-credit-btn {
    background-color: white !important;
    color: #6A097D !important;
    text-transform: capitalize !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    border: none !important;
}

.pp-plan-credit-btn-starter {
    background-color: #F0F0F0 !important;
    color: #6A097D !important;
    text-transform: capitalize !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    border: none !important;
}

.pp-plan-slider-input-item {
    margin: 5px auto !important;
}

.pp-plan-slider-input-container {
    align-items: baseline;
    justify-content: start;
    /* margin-top: -33px; */
}

.pp-plan-slider-input {
    max-width: 23px !important;
}

.pp-plan-slider .MuiSlider-mark {
    height: 0px !important;
}

.pp-plan-head-label-main-item1 {
    margin: 0px auto !important;
    padding: 5px !important;
}

.pp-plan-RecommendedLabel {
    display: flex !important;
    justify-content: end;
    min-height: 30px !important;
    max-height: 30px !important;
}

.pp-plan-RecommendedLabel-img {
    margin-right: -15px !important;

}

.pp-plan-head-label-item {
    min-height: 30px !important;
    max-height: 30px !important;
    padding-left: 25px !important;
    margin-top: 20px !important;
}

.pp-plan-head-label-container {
    min-height: 30px !important;
    max-height: 30px !important;
}

.pp-plan-head-label-sub-item {
    min-height: 30px !important;
    max-height: 30px !important;
}

/* ------------------------------------------Pricing Card--------------------------------------------- */



/* ------------------------------------------Plans Features------------------------------------------ */

.pp-compare-plans-main-container {
    justify-content: space-between;
    min-height: 100px;
}

.pp-cp-feature-grid-title {
    font-size: 25px !important;
    font-weight: 600 !important;
    color: #6A097D !important;
}

.pp-cp-feature-grid-sub-title {
    color: #717171;
    font-size: 13px !important;
    font-weight: 500 !important;
}

.pp-cp-title-item {
    text-align: center !important;
    margin: 20px 0px 5px !important;
}

.pp-cp-outlined-btn {
    text-transform: capitalize !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    border-radius: 5px !important;
    border: 1px solid #6A097D !important;
    color: #6A097D !important;
    width: 80% !important;
}

.pp-compare-plans-content-white-sub-container {
    justify-content: space-between;
    align-items: center;
}

.pp-cpc-gb-help-icon {
    font-size: 15px !important;
    font-weight: 600 !important;
    color: #9ba5b5 !important;
}

.pp-cpc-gb-feature-text {
    color: #475467 !important;
    font-size: 15px !important;
    font-weight: 400 !important;
}

.pp-cpc-gb-feature-item {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
    height: 100px;
    background-color: white !important;
}

.pp-cpc-gb-check-icon {
    margin-bottom: -14px;
}

.pp-cp-feature-grid-title-item {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}

.pp-plan-right-border-item {
    border-right: 1px solid #ecebeb !important;
    background-color: white !important;
}

.pp-plan-feature-container1 {
    justify-content: space-between;
}

.pp-plan-feature-container2 {
    justify-content: space-between;
    min-height: 100px;
    height: 100px;
}

.pp-plans-table-item1 {
    /* bg commented bcz of dynamic plan */
    /* background-color: white; */
    /* background: #FFF; */
    /* box-shadow: 0px 2px 2px 0px rgba(230, 228, 228, 0.75); */
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.pp-plans-table-item2 {
    /* bg commented bcz of dynamic plan */
    /* background-color: white !important;
    background: #FFF !important; */
    /* box-shadow: 0px 10px 10px -10px rgba(230, 228, 228, 0.75) */
    min-height: 60px;
}

.pp-plans-table-item2-last-row {
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
}

.pp-cpc-gb-left-head-item {
    display: flex !important;
    align-items: center !important;
}

.pp-cpc-gb-left-head-text {
    font-size: 15px !important;
    color: #404754 !important;
    font-weight: 600 !important;
}

.pp-cpc-gb-left-sub-head-text {
    font-size: 12px !important;
    color: #404754 !important;
    font-weight: 500 !important;
}

.pp-plans-table-container1 {
    /* dynmic for comment */
    /* justify-content: space-between; */
    align-items: center;
}

.pp-plans-table-container2 {
    /* dynmic for comment */
    /* justify-content: space-between; */
    align-items: center;
    min-height: 100px;
    height: 100px;
}

.pp-plan-subtitle-text {
    font-size: 13px !important;
    font-weight: 500 !important;
    color: #7A7A7A !important;
}

.pp-plan-feature-btn-item {
    margin: 30px 0px !important;
    text-align: center !important;
}

.pp-plan-feature-subtitle-item {
    margin: 5px 0px !important;
}

.pp-cp-plan-names {
    font-size: 20px !important;
    font-weight: 500 !important;
    color: black !important;
}

.pp-free-plan-sub-item1 {
    padding: 0px 30px !important;
}

.pp-starter-plan-sub-item1 {
    padding: 0px 30px !important;
}

.pp-premium-plan-sub-item1 {
    padding: 0px 30px !important;
    background-color: white !important;
}

.pp-plan-dropdown {
    border-radius: 5px !important;
    background: #FBF4FF !important;
}

.pp-plan-dropdown .MuiOutlinedInput-notchedOutline {
    border: none !important;
}

.pp-plan-more-user-item-typo {
    font-size: 15px !important;
    font-weight: 500 !important;
}

.pp-cpc-gb-left-card-media {
    width: auto !important;
    height: 30px;
}

/* compare plans */

@media only screen and (min-width: 320px) and (max-width: 899px) {

    .pp-compare-plans-main-item {
        padding: 5px !important;
    }
}

@media only screen and (min-width: 725px) and (max-width: 766px) {
    .pp-cp-outlined-btn {
        font-size: 14px !important;
    }
}

@media only screen and (min-width: 641px) and (max-width: 725px) {
    .pp-cp-outlined-btn {
        padding: 10px 0px !important;
        font-size: 14px !important;
    }
}


@media only screen and (min-width: 575px) and (max-width: 641px) {

    .pp-cp-outlined-btn {
        text-transform: capitalize !important;
        font-size: 12px !important;
        font-weight: 500 !important;
        border-radius: 8px !important;
        border: 1px solid #6A097D !important;
        color: #6A097D !important;
        padding: 10px 0px !important;
    }

}

@media only screen and (min-width: 457px) and (max-width: 575px) {

    .pp-cp-outlined-btn {
        font-size: 11px !important;
        font-weight: 500 !important;
        padding: 10px 0px !important;
    }
}

@media only screen and (min-width: 320px) and (max-width: 499px) {

    /* .pp-cpc-gb-left-head-text {
        font-size: 11px !important;
        color: #404754 !important;
        font-weight: 400 !important;
    }

    .pp-cpc-gb-feature-text {
        color: #475467 !important;
        font-size: 11px !important;
        font-weight: 400 !important;
    } */

}


@media only screen and (min-width: 320px) and (max-width: 1199px) {

    .pp-plans-panel-sub-item {
        margin: 0px 0px 30px !important;
    }
}

@media only screen and (min-width: 600px) and (max-width: 899px) {

    .pp-plans-panel-main-container {
        justify-content: center;
    }
}


@media only screen and (min-width: 0px) and (max-width: 599px) {

    .pp-main-title {
        font-size: 18px !important;
        font-weight: 600 !important;
    }

    .pp-main-color-title {
        color: #6A097D;
        font-size: 18px !important;
        font-weight: 600 !important;
    }

    .pp-title-item {
        padding: 0px 10px !important;
    }

    .pp-sub-title-typo {
        font-size: 13px !important;
    }

    .pp-plan-package-label-title {
        font-size: 20px !important;
    }

    .pp-plan-package-label-title-premium {
        font-size: 20px !important;
    }

    .pp-plan-package-label-sub-title {
        font-size: 13px !important;
    }

    .pp-plan-package-label-sub-title-premium {
        font-size: 13px !important;
    }

    .pp-plans-points {
        font-size: 13px;
    }

    .pp-plans-list-points {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .pp-plan-package-dis-amount-label {
        font-size: 11px !important;
    }

    .pp-plan-more-user-item-typo {
        font-size: 12px !important;
    }

    .pp-plan-talk-to-sales-btn {
        padding: 6px !important;
    }

    .pp-plan-credit-btn-selected {
        min-width: 56px !important;
        padding: 3px 5px !important;
    }

    .pp-plan-Premium-talk-to-sales-btn {
        padding: 6px !important;
    }

    .pp-plan-credit-btn {
        min-width: 56px !important;
        padding: 3px 5px !important;
    }

    .pp-plan-credit-btn-starter {
        min-width: 56px !important;
        padding: 3px 5px !important;
    }

    .pp-plan-more-user-item {
        margin: 0px !important;
    }

    .pp-plan-package-dis-amount-label-item-typo {
        font-size: 13px !important;
    }

    .pp-plan-package-dis-amount-label-item {
        margin: 8px 0px 6px 0px !important;
    }

    .pp-plan-btn {
        padding: 5px 21px !important;
    }

    .pp-plan-btn-selected {
        padding: 5px 21px !important;
    }

    .pp-plan-head-label-item {
        padding-left: 18px !important;
    }

    .pp-plan-check-icon-svg {
        height: 16px !important;
        width: auto !important;
    }

    .pp-plan-package-amount-month-label {
        font-size: 15px !important;
    }

    .pp-plan-package-amount-label {
        font-size: 24px !important;
    }

    .pp-cp-plan-names {
        font-size: 13px !important;
    }

    .pp-cp-feature-grid-title {
        font-size: 18px !important;
    }

    .pp-plan-subtitle-text {
        font-size: 12px !important;
    }

    .pp-cpc-gb-left-card-media {
        width: auto !important;
        height: 20px;
    }

    .pp-free-plan-sub-item1 {
        padding: 0px 3px !important;
    }

    .pp-starter-plan-sub-item1 {
        padding: 0px 3px !important;
    }

    .pp-premium-plan-sub-item1 {
        padding: 0px 3px !important;
    }

    .pp-plan-dropdown .MuiSelect-select {
        font-size: 15px !important;
        padding: 3.5px 14px !important;
    }

    .pp-plans-table-item2 {
        min-height: 50px;
        height: 50px;
    }

    .pp-plans-table-container2 {
        min-height: 50px;
        height: 50px;
    }

    .pp-cpc-gb-feature-item {
        min-height: 50px;
        height: 50px;
    }

    .pp-plans-table-item1 {
        background-color: transparent !important;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        background: transparent !important;
        box-shadow: unset;
    }

    .pp-plan-right-border-item {
        /* border-right: 0px  !important; */
    }

    .pp-cp-feature-grid-title-item {
        justify-content: center;
        display: flex;
    }

    .pp-plans-bg-main-item {
        padding: 25px 0px !important;
        margin: 60px 0px 0px !important;
    }

    .pp-cp-feature-grid-title-item {
        margin-top: 0px !important;
    }

    .pp-plan-feature-container1 {
        margin-bottom: 15px !important;
    }

    .pp-plan-dropdown {
        /* max-width: 85px; */
    }

    .pp-main-item {
        margin: 80px auto 0px !important;
    }

    .pp-monthly-annually-item {
        margin: 20px auto !important;
    }

    .pp-plans-panel-sub-item {
        margin: 0px 0px 30px !important;
        min-height: auto !important;
    }

    .pp-plans-panel-main-container {
        gap: 0px;
    }

    .pp-plans-panel-sub-item:hover {
        box-shadow: none !important;
    }

}

@media only screen and (min-width: 600px) and (max-width: 899px) {

    .pp-plan-feature-container2 {
        min-height: 150px;
        height: 150px;
    }

    .pp-plans-table-container2 {
        min-height: 150px;
        height: 150px;
    }

    .pp-cpc-gb-feature-item {
        min-height: 150px;
        height: 150px;
    }

}





/* ------------------------------------------Plans Features------------------------------------------ */