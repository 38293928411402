.brws-extc-main-item {
    background: linear-gradient(1deg, #F7E9FF 0%, #FFF 100%) !important;
    padding: 40px 0px 180px 0px !important;
}

.brws-extc-head-item {
    margin: 20px auto 20px !important;
    text-align: center;
}

.brws-extc-head-normal-text {
    font-size: 30px !important;
    font-weight: 600 !important;
    color: black !important;
    border-bottom: 3px solid #6C0D7F !important;
}

.brws-extc-head-color-text {
    font-size: 30px !important;
    font-weight: 600 !important;
    color: #6C0D7F !important;
}

.brws-extc-iframe-item {
    margin: 30px auto !important;
    width: 100%;
}

.brws-extc-iframe-item-img {
    width: 850px !important;
    display: flex;
    justify-content: center;
    height: 500px;
    margin: auto;
    border-radius: 15px;
    max-width: 90% !important;
    box-shadow: 0px 0px 2px 0 rgba(0, 0, 0, 0.25) !important;
}
.brws-extc-iframe-desc-text {
    color: var(--blue-grey-blue-grey-800, #243B53) !important;
    font-size: 20px !important;
    font-weight: 600 !important;
}

.brws-extc-iframe-desc-text-item {
    margin: 20px auto !important;
    text-align: center;
}

.brws-extc-ai-partner-text-item {
    margin: 30px auto 20px !important;
}

.brws-extc-normal-text {
    font-size: 30px !important;
    font-weight: 600 !important;
    color: black !important;
}

.brws-extc-text-item {
    margin: 10px auto 10px !important;
    text-align: center;
}

.brws-extc-color-text {
    font-size: 30px !important;
    font-weight: 600 !important;
    color: #6C0D7F !important;
}

.brws-extc-subtitle-text-item {
    margin: 20px auto 15px auto !important;
    text-align: center;
}

.brws-extc-subtitle-text {
    font-size: 15px !important;
    font-weight: 600 !important;
    color: black !important;
}

.brws-signup-btn {
    color: #6C0D7F !important;
    border: 1px solid #6C0D7F !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    border-radius: 5px !important;
    text-transform: none !important;
}

.brws-add-chrome-btn {
    background-color: #6C0D7F !important;
    color: white !important;
    font-size: 20px !important;
    font-weight: 500 !important;
    border-radius: 5px !important;
    text-transform: none !important;
}

.brws-signup-btn-item {
    margin: 20px 15px !important;
}

.brws-add-chrome-btn-item {
    margin: 20px 15px !important;
}

@media only screen and (min-width: 320px) and (max-width: 599px) {

    .brws-extc-head-normal-text {
        font-size: 16px !important;
    }
    .brws-extc-head-color-text {
        font-size: 16px !important;
    }
    .brws-extc-iframe-desc-text {
        font-size: 13px !important;
    }
    .brws-extc-normal-text {
        font-size: 16px !important;
    }
    .brws-extc-color-text {
        font-size: 16px !important;
    }
    .brws-extc-subtitle-text {
        font-size: 13px !important;
    }
    .brws-signup-btn {
        font-size: 15px !important;
    }
    .brws-add-chrome-btn {
        font-size: 15px !important;
    }
    .brws-extc-iframe-item-img {
        width: 300px !important;
        height: fit-content;
    }
    .brws-extc-iframe-item {
        margin: 10px auto !important;
    }
    .brws-extc-ai-partner-text-item {
        padding-top: 0px !important;
    }
    .brws-extc-main-item {
        padding: 20px 0px 180px 0px !important;
    }
}