/* CSS FOR MARQUEE ANIMATION */
.marquee-wrapper {
  background: transparent;
  text-align: center;
}

.marquee-wrapper .banner-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.testimonial-container {
  background: linear-gradient(to top, #FFF 0%, #F9EDFF 50%);
  padding: 80px 0;
}

.testimonial-content-top-text {
  color: #6a097d;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  font-weight: 600;
  line-height: 100%; /* 18px */
  letter-spacing: 3.78px;
  padding-bottom: 15px;
}
/* .marquee-inner span{
  float:left;
  width:50%;
} */
.marquee-wrapper .marquee-block {
  --total-marquee-items: 10;
  height: 250px;
  width: calc(370px * (var(--total-marquee-items)));
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  margin: 0px auto;
  /* background: white; */
  padding: 30px 0;
}

.marquee-inner {
  display: block;
  width: 200%;
  position: absolute;
}

.marquee-inner.to-left {
  animation: marqueeLeft 100s linear infinite;
}

.marquee-inner.to-right {
  animation: marqueeRight 100s linear infinite;
}

.marquee-inner.to-right.hovered,
.marquee-inner.to-left.hovered {
  animation-play-state: paused;
}

.marquee-item {
  width: 280px;
  height: 400px;
  display: inline-block;
  margin: 0 10px;
  float: left;
  transition: all .2s ease-out;
}

.marquee-item:hover {
  scale: 1.02;
}

.marquee-inner:hover {
  animation-play-state: paused;


}

@keyframes marqueeLeft {
  0% {
    left: 0;
    /* transform: translateX(-100%) */
  }

  100% {
    left: -100%;
    /* transform: translateX(0%) */
  }
}

@keyframes marqueeRight {
  0% {
    left: -100%;

    /* transform: translateX(0%) */
  }

  100% {
    left: 0;
    /* transform: translateX(-100%) */
  }
}

.testimonial-top-header {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 42px;
}

.separator {
  margin-left: 10px;
  margin-right: 10px;
  font-weight: 900;
  color: #dce8fd;
}



/* DESIGN FOR TESTIMONIAL BOX */

.testimonial .user-name-block {
  display: flex;
  flex-direction: row;
}

.testimonial .user-name {
  display: flex;
  align-items: center;
}

.testimonial {
  background-color: white;
  border: 1px solid #e1e8ed;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
  /* margin-bottom: 10px; */
  width: 350px;
  height: 200px;
  display: flex;
  box-shadow: -10px 10px 1px rgba(231, 235, 243, 0.4);
  flex-direction: column;
  justify-content: space-between;
}

.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.tweet-content {
  margin-top: 10px;
  font-size: 16px;
  text-align: left;
}


/* MEDIA QUERY */

@media screen and (max-width: 1200px) {
  /* .separator {
    display: none;
  } */

  .testimonial-top-header{
    font-size: 28px;
  }
  /* .banner-title-first {
    line-height: 110%;

  }

  .banner-title-second {
    display: inline-block;
    position: relative;
    left: 55px;
    line-height: 110%;
  }

  .banner-title-third {
    position: relative;
    line-height: 110%;
    left: 180px;
  } */


}

@media screen and (max-width: 800px) {
  .separator {
    display: none;
  }

  .testimonial-top-header {
    flex-direction: column;
    display: flex;
    line-height: 100%;
    position: relative;
  }
}
@media screen and (max-width: 500px) {
  
  .testimonial{
    width: 280px;
    height: 180px;
  } 
  .tweet-content{
    font-size: 13px;
  }
  .marquee-wrapper .marquee-block{
    height:210px;
    padding: 0px;
  }

}

