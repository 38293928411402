/* Head */
.cw-head-section-main-item {
    margin: 100px auto 0px !important;
}



/* Head */

.cw-bz-section-main-item {
    padding: 80px 0px 60px !important;
    margin: 100px auto !important;
    background-color: rgba(247, 233, 255, 0.3);
}


/* info */

.cw-info-section-main-item {
    margin: 0 auto !important;
    /* max-width: 1200px !important; */
    /* background-color: #FEFEFE; */
    background-image: url('../../assests/ChatbotWhatsapp/cw-waves-bg.png');
    background-position: start;
    background-size: contain;
    background-repeat: no-repeat;
}

.cw-normal-text-color {
    color: #1F384C !important;
}

.cw-highlighted-text-color {
    color: #6A097D !important;
}

/* info */



/* tryit */
.cw-tryit-main-item {
    background-color: #6A097D;
    padding: 100px 80px !important;
    margin: 100px auto !important;
}

/* tryit */


/* custom */
.cw-custom-main-item {
    margin: 50px auto !important;
}

/* custom */