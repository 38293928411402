.newscrollelement-main-div {
  background-color: #f9edff;
  /* height: 300vh; */
  display: flex;
  flex-direction: column;
}

.abcdefg {
  /* height: 60vh; */
  width: 70%;
  background-color: transparent;
  border-radius: 20px;
  /* margin-top: 250px; */
}
.newscrollelement-main-div-inner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 100px;
  /* flex-direction: column; */
}

.newscrollelement-main-text {
  height: 100vh;
  color: black;
  width: 50%;
  align-items: start;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  /* margin-top: 400px; */
}
.tempDiv {
  height: 170px;
  width: 50%;
}

.newscrollelement-main-image-wrapper {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: end;
  /* background: transparent; */
}

.newscrollelement-main-image-container {
  /* width: 650px;
  height: 650px; */
  object-fit: contain;
}

.newscrollelement-main-image {
  /* width: 650px;
  height: 650px;
  max-width: 110%; */
  width: 105%;
  object-fit: contain;
  border-radius: 20px !important;
}

.animateTextFadeIn {
  animation: fadeIn 1s ease-in-out;
}
.animateTextFadeOut {
  animation: fadeIn 1s;
}
#newScrollText {
  color: #000;
  margin-top: 15px;
  font-size: 20px;
  font-weight: 500;
}
.small-title {
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 3.78px;
  text-transform: uppercase;
  background: -webkit-linear-gradient(90deg, #6a097d 50%, #0a6add 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 25px;
}

.small-title-icon {
  padding-right: 15px;
  transform: translateY(5px);
}

#newScrollTitle {
  color: #333;
  font-weight: 600;
  font-size: 52px;
  line-height: normal;
  margin-top: 10px;
}
#newScrollTitleLastWord {
  color: #6a097d;
  font-weight: 600;
  font-size: 58px;
  line-height: normal;
  margin-top: 15px;
  margin-left: 15px;
}
#newScrollDescription {
  font-weight: 500;
  color: #7a7a7a;
  font-size: 18px;
  margin-top: 20px;
  line-height: 150%; /* 27px */
}
.newscrollelement-main-text-inner-div {
  width: 80%;
  display: flex;
  flex-direction: column;
}
.newscroll-learn-more-button-forward-icon {
  padding-left: 10px;
}
.css-1oi22rp-MuiButtonBase-root-MuiButton-root:hover {
  background-color: transparent !important;
  border: none !important;
}
/* .newscroll-learn-more-button {
  color
} */
/* @keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
} */

@keyframes fadeIn {
  0% {
    transform: translateY(150px); /* Start 30px below */
    opacity: 0;
  }
  40% {
    transform: translateY(0); /* Back to main position */
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 0;
  }
}

@media screen and (max-width: 1650px) {
  #newScrollTitle,
  #newScrollTitleLastWord {
    font-size: 45px;
  }
}

@media screen and (max-width: 1500px) {
  #newScrollTitle,
  #newScrollTitleLastWord {
    font-size: 40px;
  }
}

@media screen and (max-width: 1300px) {
  #newScrollTitle,
  #newScrollTitleLastWord {
    font-size: 35px;
  }
  #newscrollelement-main-text-inner-div {
    width: 85%;
  }
  .newscrollelement-main-div-inner {
    column-gap: 50px;
  }
}
