.gitex-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1400 !important;
    height: 650;
    max-width: 92%;
    max-height: 80%;
    padding: 30px;
    background: linear-gradient(50deg, #0A6ADD 20%,  #6A097D 55%);
    border-radius: 20px;
}

.gx-popup-description {
    color: white;
    font-size: 25px !important;
    font-weight: 600 !important;
    width: 1000px !important;
    max-width: 80% !important;
    text-align: center !important;
    margin: 40px auto !important;
}

.gitex-image-container {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.popup-gitex-image {
    width: 800px;
    max-width: 100%;
    margin-inline: auto;
}

.gx-popup-desk-info {
    color: white !important;
    justify-content: center !important;
}

.gx-popup-desk-info span {
    font-size: 17px;
    font-weight: 600;
}

.gx-popup-desk-info h6 {
    font-size: 32px;
    font-weight: 700;
}

@media (max-width: 900px) {
    .gx-popup-description {
        font-size: 25px !important;
        max-width: 90% !important;
    }
}

@media (max-width: 500px) {
    .gx-popup-description {
        font-size: 20px !important;
        max-width: 100% !important;
        margin-top: 20px !important;
    }
    
    .gitex-image-container {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .popup-gitex-image {
        max-width: 100%;
    }

    .gx-popup-desk-info span {
        font-size: 15px;
        font-weight: 600;
    }
    
    .gx-popup-desk-info h6 {
        font-size: 25px;
        font-weight: 700;
    }
    
}

@media (max-height: 768px) {
    .gx-popup-description {
        font-size: 19px !important;
        margin-top: 10px !important;
    }

    .popup-gitex-image {
        width: 80%;
    }

    .gx-popup-desk-info h6 {
        font-size: 23px;
        font-weight: 700;
    } 
}

@media (max-height: 600px) {
    .popup-gitex-image {
        width: 60%;
    }
}