.pr-ai-main-item {
    margin: 70px 0px 0px !important;
}

/**************** SECTION 1 ******************/

.pr-ai-main-section1 {
    background-image: url('../../assests/Paraphase-Background.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 100px 20px !important;
    height: auto;
}

.pr-ai-sub-section {
    margin: 0 auto !important;
}

.pr-ai-sub-section-container {
    justify-content: space-between;
    align-items: center;
    /* gap: 200px; */
}

.browser-img {
    width: 100%;
    max-width: 600px;
    display: flex;
    justify-content: center;
    margin: auto;
    border-radius: 5px;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.pr-ai-main-head {
    font-size: 46px !important;
    font-weight: 600 !important;
    color: white !important;
}

.pr-ai-sub-head {
    font-size: 20px !important;
    font-weight: 400 !important;
    color: white !important;
}

.pr-ai-subtitle {
    font-size: 15px !important;
    font-weight: 400 !important;
    color: white !important;
}

.pr-start-paraphrase-btn {
    font-size: 18px !important;
    font-weight: 500 !important;
    color: white !important;
    background-color: #6A097D !important;
    text-transform: none !important;
    align-items: center;
}

.pr-signup-img {
    width: 200px;
    height: auto;
    object-fit: fill;
    cursor: pointer;
    border-radius: 2px;
}

.pr-ai-main-head-item,
.pr-ai-sub-head-item {
    margin: 3px 0px !important;
}

.pr-ai-subtitle-item {
    margin: 20px 0px 5px !important;
}

.browser-img-item {
    margin-top: -45px !important;
}


.pr-ai-points-list-main-item {
    width: 270px !important;
    min-width: 270px !important;
    max-width: 270px !important;
    margin: 10px 10px 10px 0px !important;
    border-radius: 30px;
    background-color: #fff;
    height: 45px;
}

.pr-ai-points-list-main-container {
    justify-content: space-between;
    height: 100%;
    align-items: center;
}

.pr-ai-points-part1 {
    width: 20%;
    text-align: center;
}

.pr-ai-points-part2 {
    /* background-color: #EFD7FE; */
    background-color: #f4e1ff;
    border-radius: 30px;
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 15px;
}

.pr-ai-points-part2 span {
    font-size: 15px !important;
    font-weight: 600 !important;
    color: #6A097D !important;
    text-transform: capitalize !important;
}

.pr-ai-sec1-italic-label {
    font-size: 20px !important;
    font-weight: 500 !important;
    /* font-style: italic !important; */
}

.pr-ai-points-icon {
    width: 23px;
    height: auto;
}

.pr-start-btn-main-item {
    margin-top: 35px !important;
}


@media screen and (max-width : 600px) and (min-width : 0px) {
    .pr-ai-points-list-main-item {
        margin: 10px 0px 10px 0px !important;
    }


    .pr-ai-points-list-main-container {
        justify-content: space-between;
        gap: 0;
    }

    .pr-ai-main-section1 {
        padding: 50px 15px !important;
    }

}

@media screen and (max-width : 375px) and (min-width : 0px) {
    .pr-ai-points-list-main-item {
        min-width: 100% !important;
        max-width: 100% !important;
    }

    .pr-ai-points-list-main-container {
        justify-content: center;
        gap: 0px;
    }
}

/**************** SECTION 1 ******************/


/**************** SECTION 2 ******************/

/* GROUP 1 */

.pr-ai-section-global-head-highlighted {
    font-size: 30px !important;
    font-weight: 500 !important;
    color: #6A097D !important;
}

.pr-ai-section-global-head {
    color: #1F384C !important;
    font-size: 30px !important;
    font-weight: 500 !important;
}

.pr-ai-section2-sub-head {
    color: #1F384C !important;
    font-size: 16px !important;
    font-weight: 500 !important;
}

.pr-ai-main-section2 {
    text-align: center !important;
    margin: 90px 0px 60px !important;
}

.pr-ai-sub-section2-item {
    margin: 0 auto !important;
}

.pr-ai-section2-head-item {
    margin: 10px auto !important;
    text-align: center !important;
}

.pr-ai-section2-sub-head-item {
    margin: 10px auto !important;
    text-align: center !important;
}

.pr-ai-section2-groupImg1 {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: auto;
    height: auto;

    /* max-width: 600px; */
}

.pr-ai-section2-groupImg1-item {
    z-index: 1;
}

.pr-ai-sec2-bg-left-img-item {
    position: absolute;
    top: 10px;
    left: 10px;
}

.pr-ai-sec2-bg-right-img-item {
    position: absolute;
    top: 10px;
    right: 10px;
}



/* GROUP 1 */



/* GROUP 2 */

.pr-ai-sec2-content-group2-item {
    margin: 40px auto !important;
    /* background-color: #F8F8F8 !important; */
    border-radius: 20px;
    padding: 30px 30px;
}

.pr-ai-sec2-content-group1-item {
    /* margin: 60px auto !important;
    padding: 30px 45px; */
}

.pr-ai-sec2-bgContent-group1-item {
    /* background-color: #F8F8F8 !important; */
}

.pr-ai-sec2-bgContent-main-item {
    margin: 60px auto !important;
    padding: 65px 55px !important;
    border-radius: 20px;
    position: relative !important;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25) !important;
}

.pr-ai-group-head-text {
    color: #1F384C !important;
    font-size: 18px !important;
    font-weight: 600 !important;
}


/* GROUP 2 */


/* GROUP 3 */

.pr-ai-sec2-content-group3-item {
    margin: 40px auto !important;
    border-radius: 20px;
    padding: 30px 30px;
}


/* GROUP 3 */


/* GROUP 4 */

.pr-ai-sec2-content-group4-item {
    margin: 40px auto !important;
    background-color: #F8F8F8 !important;
    border-radius: 20px;
    padding: 30px 30px;
}

/* GROUP 4 */

.pr-ai-sec2-group-main-head-label-item {}


.pr-ai-sec2-group-main-head-label {
    background: rgb(17, 136, 218) !important;
    background: linear-gradient(360deg, rgba(17, 136, 218, 1) 0%, rgba(106, 9, 125, 1) 100%) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    letter-spacing: 3px !important;
}

.pr-ai-sec2-group-main-head-item {
    margin: 10px 0px !important;
}

.pr-ai-sec2-group-main-subhead-item {
    margin: 5px 0px !important;
}

.pr-ai-sec2-group-list-item {
    margin: 5px 0px !important;
}

.pr-ai-start-alignment {
    text-align: start !important;
}

.pr-ai-sec2-group-main-head {
    font-size: 30px !important;
    font-weight: 500 !important;
    color: #1F384C !important;
}

.pr-ai-sec2-group-main-subhead {
    font-size: 18px !important;
    font-weight: 500 !important;
    color: #1F384C !important;
}

.pr-ai-sec2-list-head {
    color: #6A097D !important;
    font-size: 18px !important;
    font-weight: 600 !important;
}

.pr-ai-sec2-list-points {
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #1F384C !important;
}

.pr-ai-sec2-group-list-subitems {
    margin: 10px 0px !important;
}

.pr-ai-sec2-images {
    width: 25px;
    height: auto;
    margin-top: 5px;
}


/**************** SECTION 2 ******************/



/**************** SECTION 3 ******************/

.pr-ai-generalized-main-head {
    font-size: 30px !important;
    font-weight: 500 !important;
    color: black !important;
}

.pr-ai-generalized-main-highlighted-head {
    font-size: 30px !important;
    font-weight: 500 !important;
    color: #6A097D !important;
}

.pr-ai-generalized-main-head-item {
    margin: 10px auto !important;
    text-align: center !important;
}

.pr-ai-generalized-subhead {
    color: #1F384C;
    font-size: 15px;
    font-weight: 500;
}

.pr-ai-generalized-subhead-item {
    margin: 3px auto !important;
    text-align: center !important;
}

.pr-ai-generalized-main-item {
    margin: 60px auto !important;
}

.pr-ai-generalized-img {
    width: 100%;
    max-width: 600px;
    display: flex;
    justify-content: center;
    margin: auto;
}

.pr-ai-generalized-img-item {
    margin: 10px auto !important;
}

.pr-ai-main-section3 {
    margin: 0px 0px !important;
}

.pr-ai-images-main-item {
    background-color: #F7E9FF;
    margin: 60px auto !important;
    padding: 60px 30px 60px !important;
}

.pr-sec3-content-item {
    margin: 0 auto !important;
    text-align: center !important;
}

.pr-sec3-content-container {
    justify-content: space-between;
    align-items: start;
    /* gap: 15px; */
}

.pr-sec3-imgs {
    margin: auto;
    max-width: 200px;
    width: 100%;
    height: auto;
}

.pr-sec3-global-text {
    font-size: 18px !important;
    font-weight: 600 !important;
    color: #1F384C !important;
}

.pr-sec3-head-item {
    margin: 0px auto 50px !important;
    text-align: center !important;
}

.pr-sec3-head-text {
    font-size: 30px;
    font-weight: 500;
    color: #1F384C;
}

.pr-sec3-global-subtext {
    font-size: 15px !important;
    font-weight: 500 !important;
    color: #1F384C !important;
}

.pr-sec3-global-subtext-item {
    margin: 5px auto !important;
}

.pr-ai-paraphrasing-subhead {
    font-size: 17px !important;
    font-weight: 500 !important;
    color: #1F384C;
}

.pr-ai-paraphrasing-head-item {
    margin: 10px auto !important;
}

.pr-ai-paraphrasing-subhead-item {
    margin: 10px 0px !important;
}




/**************** SECTION 3 ******************/


/**************** SECTION 4 ******************/
.pr-diff-main-head-text {
    font-size: 30px !important;
    font-weight: 600 !important;
    color: #1F384C !important;
}

.pr-ai-dif-left-head-item {
    margin: 0 auto !important;
    background-color: #0A6ADD !important;
    border-radius: 10px !important;
    padding: 15px 20px !important;
    text-align: center;
    z-index: 1 !important;
}

.pr-ai-dif-right-head-item {
    margin: 0 auto !important;
    background-color: #6A097D !important;
    border-radius: 10px !important;
    padding: 15px 20px !important;
    text-align: center;
    z-index: 1 !important;
}

.pr-ai-diff-left-section {
    border-radius: 50px;
    background: #E9F2FC;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    margin: -20px auto 0px !important;
    padding: 20px !important;
    width: 550px !important;
    max-width: 550px !important;
    max-height: 1156px;
    height: 1156px;
}

.pr-ai-diff-right-section {
    border-radius: 50px;
    background: #F7E9FF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    margin: -20px auto 0px !important;
    padding: 20px !important;
    width: 550px !important;
    max-width: 550px !important;
}

.pr-ai-diff-both-sec-item {
    width: 450px;
    max-width: 450px;
}

.pr-ai-diff-head-text {
    color: #fff !important;
    font-size: 18px !important;
    font-weight: 500 !important;
}

.pr-ai-diff-left-sec-item1 {
    margin: 20px auto !important;
    text-align: center;
    min-height: 195px !important;
    height: 195px !important;
}

.pr-ai-diff-sec-global-subtext {
    color: #1F384C !important;
    font-size: 15px !important;
    font-weight: 500 !important;
}

.pr-ai-diff-sec-point-global-text {
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #1F384C !important;
}

.pr-ai-diff-sec-point-global-subtext {
    color: #1F384C !important;
    font-size: 13px !important;
    font-weight: 500 !important;
}

.pr-ai-diff-arrow {
    width: 15px;
    height: auto;
}

.pr-ai-diff-sec2-point-global-text {
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #6A097D !important;
}

/* ----------------- */

.pr-ai-que-main-item {
    background-color: #F7E9FF !important;
    padding: 70px 0px !important;
    margin: 60px auto !important;
}

.pr-ai-que-main-head-text {
    color: #6A097D !important;
    font-size: 17px !important;
    font-weight: 600 !important;
}

.pr-ai-que-main-sub-text {
    color: #1F384C !important;
    font-size: 15px !important;
    font-weight: 500 !important;
}


.pr-use-tool-main-head {
    font-size: 30px !important;
    font-weight: 500 !important;
    color: #1F384C !important;
}

/* ----------------- */

/* ----------------- */
.pr-ai-use-tool-main-item {
    padding: 30px 0px !important;
    background-image: url('../../assests/FeedbackWaes.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 60px auto !important;
}


.pr-ai-use-tool-main-head {
    color: #6A097D !important;
    font-size: 17px !important;
    font-weight: 600 !important;
}

.pr-ai-use-tool-sub-head {
    color: #4C4A4D !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}

.pr-ai-usetool-card {
    margin: 0px 60px 60px 0px !important;
    padding: 15px 10px 10px !important;
    border: 1px solid #D9D9D9;
    background-color: #fff;
    border-radius: 10px;
    max-width: 350px;
    width: 350px;
}

/* ----------------- */







.pr-tryit-section-item {
    background-image: url('../../assests/Tryit-Bg.png');
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: inherit !important;
    margin: 0px auto 0px !important;
    max-width: 950px !important;
    width: 100% !important;
    position: relative;
    padding: 50px 0px 50px 50px !important;
    /* height: 264px !important; */
    border-radius: 20px;
}

.pr-tryit-section-subimg {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 100px;
    height: auto;
    object-fit: fill;
}

.pr-ai-tryit-main-head {
    color: #fff !important;
    font-size: 25px !important;
    font-weight: 500 !important;
}

.pr-ai-tryit-sub-head {
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 500 !important;
}

/**************** SECTION 4 ******************/


/**************** SECTION 5 ******************/
.pr-ai-main-section5 {
    background: linear-gradient(180deg, rgba(247, 233, 255, 1) 44%, rgba(247, 233, 255, 1) 66%, rgba(247, 233, 255, 1) 87%, rgba(255, 255, 255, 1) 100%);
    height: auto;
    padding-bottom: 100px !important;
    margin: 60px 0px !important;
}

.pr-ai-white-overlap-item {
    background-color: #fff !important;
    border-radius: 20px !important;
    z-index: 1 !important;
    margin: 0 auto !important;
    margin-bottom: -80px !important;
    padding: 30px 30px 30px 50px !important;
}

.pr-ai-question-item {
    text-align: center !important;
    margin: 20px 0px !important;

}

.pr-ai-faq-main-head {
    color: #6A097D !important;
    font-size: 25px !important;
    font-weight: 600 !important;
}

.pr-ai-faq-sub-head {
    color: #1F384C !important;
    font-size: 15px !important;
    font-weight: 600 !important;
}

.pr-ai-faq-accordion {
    box-shadow: none !important;
    position: inherit !important;
    border: 2px solid #fff !important;
    margin-top: 15px !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}

.pr-ai-expand-accordion-summary {
    cursor: auto !important;
    min-height: 60px !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    background: #fff !important;
    padding: 0px 15px !important;
}

.pr-ai-expand-accordion-summary .MuiAccordionSummary-expandIconWrapper {
    transform: rotate(270deg) !important;
}

.pr-ai-expand-accordion-summary .Mui-expanded {
    transform: rotate(360deg) !important;
    margin: 0px 0px !important;
}

/**************** SECTION 5 ******************/


/**************** OVERLAP FOOTER ******************/

.pr-overlap-item {
    position: relative;
}

.pr-ai-footer-main-item {
    position: absolute;
    width: 100%;
    top: -80px;
}

.pr-ai-chatbot-item {
    position: absolute;
    width: 100%;
    top: -180px;
}

.pr-ai-footer-main-container {
    align-items: center;
    position: relative;
    width: 100% !important;
    /* margin-top: 80px !important; */
}

.pr-overlap-head-item span {
    font-size: 30px;
    font-weight: 500;
    color: #6A097D;
}

.pr-overlap-sub-item span {
    font-size: 16px;
    font-weight: 600;
    color: #1F384C;
}

.pr-contact-icons {
    width: 20px;
    height: auto;
}

.pr-ai-avtar {
    width: 80px !important;
    height: 80px !important;
    border-radius: 50%;
}


/**************** OVERLAP FOOTER ******************/







/**************** GLOBAL CSS ******************/
.pr-ai-global-head-text {
    font-size: 30px !important;
    font-weight: 500 !important;
    color: #1F384C !important;
}

.pr-ai-global-head-highlighted-text {
    font-size: 30px !important;
    font-weight: 500 !important;
    color: #6A097D !important;
}


/**************** GLOBAL CSS******************/

@media screen and (min-width : 1535px) {
    .pr-ai-points-list-main-item {
        margin: 10px 20px 10px 0px !important;
    }
}


@media screen and (max-width : 600px) and (min-width : 0px) {
    .pr-ai-section-global-head-highlighted {
        font-size: 23px !important;
        font-weight: 500 !important;
        color: #6A097D !important;
    }

    .pr-ai-section-global-head {
        color: #1F384C !important;
        font-size: 23px !important;
        font-weight: 500 !important;
    }

    .pr-ai-section2-sub-head {
        color: #1F384C !important;
        font-size: 15px !important;
        font-weight: 500 !important;
    }

    .pr-ai-sec2-group-main-head {
        font-size: 23px !important;
        font-weight: 500 !important;
        color: #1F384C !important;
    }

    .pr-ai-sec2-group-main-subhead {
        font-size: 15px !important;
        font-weight: 500 !important;
        color: #1F384C !important;
    }

    .pr-ai-sec2-list-head {
        color: #6A097D !important;
        font-size: 15px !important;
        font-weight: 600 !important;
    }

    .pr-ai-sec2-list-points {
        font-size: 14px !important;
        font-weight: 500 !important;
        color: #1F384C !important;
    }

    .pr-sec3-head-text {
        font-size: 23px !important;
        font-weight: 500 !important;
        color: #1F384C !important;
    }

    .pr-ai-sec2-images {
        width: 21px;
        height: auto;
        margin-top: 5px;
    }

    .pr-ai-sec2-bgContent-main-item {
        margin: 20px auto 20px !important;
        padding: 30px 20px !important;
    }

    .pr-ai-images-main-item {
        padding: 60px 20px 60px !important;
    }

    .pr-sec3-global-subtext {
        font-size: 14px !important;
        font-weight: 500 !important;
        color: #1F384C !important;
    }

    .pr-sec3-global-text {
        font-size: 16px !important;
        font-weight: 600 !important;
        color: #1F384C !important;
    }

    .pr-ai-main-head {
        font-size: 30px !important;
        font-weight: 600 !important;
        color: white !important;
    }

    .pr-ai-sub-head {
        font-size: 15px !important;
        font-weight: 500 !important;
        color: white !important;
    }

    .pr-ai-paraphrasing-head-item {
        text-align: center !important;
    }

    .pr-ai-global-head-text {
        color: #1F384C !important;
        font-size: 23px !important;
        font-weight: 500 !important;
    }

    .pr-ai-paraphrasing-subhead {
        font-size: 15px !important;
        font-weight: 500 !important;
        color: #1F384C;
    }

    .pr-ai-paraphrasing-subhead-item {
        text-align: center !important;
    }

    .pr-ai-global-head-highlighted-text {
        font-size: 23px !important;
        font-weight: 500 !important;
        color: #6A097D !important;
    }

    .pr-use-tool-main-head {
        font-size: 23px !important;
        font-weight: 500 !important;
        color: #1F384C !important;
    }

    .pr-ai-usetool-card {
        border: none;
        max-width: 100%;
        width: 100%;
        margin: 0px auto 0px !important;
        padding: 0px;
        background-color: transparent;
    }

    .pr-ai-use-tool-main-item {
        padding: 30px 0px !important;
        /* background-image: none !important; */
        margin: 100px auto !important;
    }

    .pr-tryit-section-item {
        /* margin: 20px auto 50px !important; */
        width: 100% !important;
        position: relative;
        /* padding: 20px 20px 20px 20px !important; */
        /* height: 264px !important; */
        border-radius: 20px;
        margin: 0 auto;
    }

    .pr-ai-tryit-main-head {
        color: #fff !important;
        font-size: 20px !important;
        font-weight: 500 !important;
    }

    .pr-ai-tryit-sub-head {
        color: #fff !important;
        font-size: 14px !important;
        font-weight: 500 !important;
    }

    .pr-tryit-section-subimg {
        position: absolute;
        bottom: 20px;
        right: 20px;
        width: 50px;
        height: auto;
        object-fit: fill;
    }

    .pr-ai-faq-main-head {
        color: #6A097D !important;
        font-size: 20px !important;
        font-weight: 600 !important;
    }

    .pr-ai-faq-sub-head {
        color: #1F384C !important;
        font-size: 14px !important;
        font-weight: 500 !important;
    }

    .pr-overlap-head-item span {
        font-size: 22px;
        font-weight: 500;
        color: #6A097D;
    }

    .pr-overlap-sub-item span {
        font-size: 14px;
        font-weight: 600;
        color: #1F384C;
    }

    .pr-overlap-head-item {
        text-align: start !important;
    }

    .pr-overlap-sub-item {
        text-align: start !important;
    }

    .pr-ai-white-overlap-item {
        margin-bottom: -80px !important;
        padding: 30px 15px 30px 15px !important;
    }

    .pr-ai-contact-label-item span {
        font-size: 14px;
        font-weight: 600;
    }

    .pr-contact-label-main-item {
        width: 50%;
        max-width: 50%;
        margin: 2px 0px !important;
    }

    .pr-contact-icons {
        width: 15px;
        height: auto;
    }

    .pr-ai-footer-main-container {
        margin-top: -60px !important;
    }

    .pr-ai-main-section5 {
        padding-bottom: 210px !important;
    }

    .pr-ai-diff-both-sec-item {
        width: 100%;
        max-width: 100%;
        margin: 20px 0px !important;
    }

    .pr-ai-generalized-main-item {
        margin: 20px auto !important;
    }
}

@media screen and (min-width : 0px) and (max-width : 405px) {
    .pr-ai-contact-label-item span {
        font-size: 15px;
        font-weight: 500;
    }

    .pr-contact-icons {
        width: 20px;
        height: auto;
    }

    .pr-contact-label-main-item {
        width: 100%;
        max-width: 100%;
        margin: 5px 0px !important;
    }

    .lp-ft-overlap-text-main-item {
        margin-bottom: 10px !important;
    }

    .pr-ai-diff-sec-point-global-text {
        font-size: 13px !important;
        font-weight: 600 !important;
        color: #1F384C !important;
    }

    .pr-ai-diff-arrow {
        width: 12px;
        height: auto;
    }

    .pr-ai-diff-sec2-point-global-text {
        font-size: 13px !important;
        font-weight: 600 !important;
        color: #6A097D !important;
    }

    .pr-ai-chatbot-item {
        position: absolute;
        width: 100% !important;
        top: -150px;
    }

}

@media screen and (min-width : 710px) and (max-width : 900px) {
    .pr-ai-chatbot-item {
        position: absolute;
        width: 100%;
        top: -170px;
    }
}

@media screen and (min-width : 901px) and (max-width : 966px) {
    .pr-ai-chatbot-item {
        position: absolute;
        width: 100%;
        top: -170px;
    }
}

@media screen and (min-width : 1200px) and (max-width : 1366px) {
    .pr-ai-chatbot-item {
        position: absolute;
        width: 100%;
        top: -170px;
    }

}

@media screen and (min-width : 685px) and (max-width : 815px) {
    .pr-ai-usetool-card {
        max-width: 290px;
        width: 290px;
    }

}

@media screen and (min-width : 600px) and (max-width : 685px) {
    .pr-ai-usetool-card {
        max-width: 70%;
        width: 70%;
    }
}

@media screen and (min-width : 900px) and (max-width : 1078px) {
    .pr-ai-diff-both-sec-item {
        width: 367px;
        max-width: 367px;
    }

    .pr-ai-diff-left-section {
        max-height: fit-content;
        height: 1255px;
    }
}

@media screen and (min-width : 600px) and (max-width : 1440px) {
    .pr-ai-white-overlap-item {
        padding: 30px 25px 30px 26px !important;
    }

    .pr-ai-avtar {
        width: 55px !important;
        height: 55px !important;
    }

    .pr-ai-main-section5 {
        padding-bottom: 200px !important;
    }

    .pr-ai-footer-main-container {
        margin-top: -60px !important;
    }
}

@media screen and (min-width : 600px) and (max-width : 900px) {
    .pr-ai-footer-main-container {
        margin-top: -80px !important;
    }
}

@media screen and (min-width : 900px) and (max-width : 1200px) {
    .pr-ai-sec2-content-group-images {
        margin: 30px 0px 30px !important;
    }

}

@media screen and (min-width : 0px) and (max-width : 1200px) {

    .pr-ai-main-section1 {
        padding: 60px 20px !important;
        height: auto;
    }

    .pr-sec1-img-item {
        margin-top: 80px !important;
    }

    .pr-ai-points-list-main-item {
        margin: 10px 20px 10px 0px !important;
    }

}

@media screen and (min-width : 0px) and (max-width : 630px) {
    .pr-ai-main-section1 {
        padding: 60px 10px !important;
        height: auto;
    }

}

@media screen and (min-width : 0px) and (max-width : 900px) {

    .browser-img-item {
        margin-top: -50px !important;
    }

    .pr-sec1-img-item {
        margin: 50px 0px 0px !important;
    }

    .pr-ai-sec2-content-group1-item {}

    .pr-ai-sec2-content-group-images {
        margin: 0px 0px 30px !important;
    }

    .pr-ai-main-section1 {
        height: auto;
    }

    .pr-ai-use-tool-main-item {
        padding: 30px 0px !important;
        /* background-image: none !important; */
        margin: 20px auto !important;
    }

    .pr-ai-diff-both-sec-item {
        width: 100%;
        max-width: 100%;
        margin: 20px 0px !important;
    }


}

@media screen and (min-width : 0px) and (max-width : 600px) {

    .pr-ai-sec2-content-group1-item,
    .pr-ai-sec2-content-group2-item,
    .pr-ai-sec2-content-group3-item,
    .pr-ai-sec2-content-group4-item {
        margin: 10px auto !important;
        padding: 30px 10px;
    }

    .browser-img-item {
        margin-top: -30px !important;
    }
}