.help-dialog-main-item {
    margin: 70px auto !important;
}


.hlp-dialog-head-section-item {
    background-color: #FBF4FF;
    padding: 0px 20px 20px;
}

.hlp-head-text {
    color: #6A097D;
    font-size: 18px;
    font-weight: 600;
}

.hlp-head-text-item {
    text-align: center !important;
    margin: 20px auto 20px !important;
}

.hlp-avtar-item {
    margin: 20px auto !important;
}

.hlp-subhead-items {
    margin: 0 auto !important;
    text-align: center !important;
}

.hlp-subhead-item1 {
    margin: 2px auto !important;
}

.hlp-subhead-item2 {
    margin: 2px auto !important;
}

.hlp-subhead-text {
    color: #6A097D !important;
    font-size: 15px !important;
    font-weight: 600 !important;
}

.hlp-subhead-text2 {
    color: #6A097D !important;
    font-size: 15px !important;
    font-weight: 500 !important;
}

.hlp-form-head-label {
    color: black;
    font-size: 15px;
    font-weight: 500;
}

.hlp-dialog-form-main-item {
    margin: 20px auto 20px !important;
}

.hlp-form-head-label-item {
    margin: 8px 0px !important;
}

.hlp-form-head-item {
    margin: 5px 0px !important;
}

.hlp-form-textfield {
    border-radius: 3px;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25) !important;
}

.hlp-form-textfield:hover {
    border-radius: 3px;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25) !important;
}



.hlp-form-submit-btn {
    background-color: #6A097D !important;
    color: white !important;
    text-transform: none !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    border-radius: 5px !important;
}

.hlp-form-submit-btn-item {
    margin: 20px auto 40px !important;
}

.hlp-dialog-cross-icon {
    position: absolute;
    right: 3%;
    top: 2%;
}