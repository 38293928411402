/* .lp-footer-main-container {
    align-items: center;
    position: relative;
    width: 100%;
}

.lp-ft-white-bg-item {
    background-color: white !important;
    height: 250px !important;
    width: 100% !important;
}

.lp-ft-white-overlap-item {

    position: relative;

    background-color: #fdf9ff !important;
    border-radius: 20px !important;
    z-index: 1;

}

.lp-ft-white-overlap-container {
    justify-content: space-between;
}

.footer-contact-us-btn {
    border-radius: 5px !important;
    background: #6A097D !important;
    text-transform: capitalize !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    color: white !important;
}

.lp-ft-footer-content-item {
    margin: 0px auto !important;
    background-color: #6A097D !important;
    color: white !important;
    padding: 0px 30px;

}

.lp-ft-footer-content-container {
    margin: 80px 0px !important;
    justify-content: space-between;

} */

.lp-footer-main-container {
    align-items: center;
    position: relative;
    width: 100%;
    /* margin-top: 80px !important; */
}

.lp-ft-white-bg-item {
    background-color: white !important;
    height: 250px !important;
    width: 100% !important;
}

.lp-ft-white-overlap-item {
    background-color: #fdf9ff !important;
    background-image: url("../../assests/footerBackgroundLinesImg.png") !important;
    border-radius: 20px !important;
    z-index: 1 !important;
    margin: 0 auto !important;
    margin-bottom: -80px !important;
    padding: 30px 30px 30px 50px !important;
}

.lp-ft-white-overlap-container {
    justify-content: space-between;
    align-items: center;
}

.footer-contact-us-btn {
    border-radius: 5px !important;
    background: #6A097D !important;
    text-transform: capitalize !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    color: white !important;
}

.lp-ft-footer-content-item {
    margin: 0px auto !important;
    background-color: #6A097D !important;
    color: white !important;
    padding: 0px 30px !important;
    border-top: 5px solid;
    border-image: linear-gradient(to right, #3B78CE 33%, #E8E021 66%);
    border-image-slice: 1;
}

.lp-ft-footer-content-container {
    margin: 135px 0px 50px !important;
    justify-content: space-between !important
}

.lp-ft-contact-btn-container {
    justify-content: end;
}

.lp-ft-footer-content-sub-item {
    margin: 20px auto !important;
}

.lp-ft-footer-content-sub-container {
    justify-content: space-between;
}



.lp-ft-content-head-item {
    margin: 20px 0px !important;
}

.lp-ft-content-head {
    font-size: 18px !important;
    font-weight: 500 !important;
}

.lp-ft-content-caption {
    font-size: 14px !important;
    font-weight: 500 !important;
}

.lp-footer-ul-points-main-item {
    justify-content: space-between;
}

.lp-footer-ul-head {
    margin-bottom: 30px !important;
}

.lp-footer-ul-points {
    list-style-type: none !important;
    margin: 0 !important;
    padding: 0 !important;
}

.lp-ft-divider-item {
    margin: 50px auto 0px !important;
    color: white !important;
}

.lp-ft-divider {
    border-color: white !important;
}

.lp-ft-cp-item {
    margin: 10px auto !important;
}

.lp-ft-cp-container {
    justify-content: space-between;
}

.lp-ft-social-item {
    text-align: end !important;
}

.lp-ft-social-link {
    color: white;
    text-decoration: none;
    margin-left: 10px;
}

.lp-ft-social-link:hover {
    color: lightgrey;
}

.lp-ft-social-link1 {
    color: white;
    text-decoration: none;
    margin-left: 10px;
}

.lp-ft-social-link1:hover {
    color: lightgrey;
}


.lp-ft-category-links {
    color: white !important;
    text-decoration: none !important;
    font-size: 15px !important;
    margin-bottom: 5px;
    font-weight: 500 !important;
}

.lp-ft-overlap-text-item {
    font-size: 20px !important;
    font-weight: 600 !important;
    margin: 10px 0px !important;
}

.lp-ft-company-info {
    font-size: 15px !important;
}

@media only screen and (min-width: 600px) and (max-width: 899px) {
    .lp-ft-left-content-item {
        margin-bottom: 20px !important;
    }

    .lp-ft-right-content-item {
        margin-top: 20px !important;
    }

    .lp-ft-footer-content-sub-item {
        margin: 0px 0px !important;
    }
}

@media only screen and (min-width: 320px) and (max-width: 599px) {
    .lp-ft-left-content-item {
        margin-bottom: 20px !important;
    }

    .lp-ft-right-content-item {
        margin-top: 20px !important;
    }

    .lp-ft-footer-content-item {
        padding: 0px 25px !important;
    }

    .lp-footer-ul-head {
        margin-bottom: 20px !important;
        font-size: 16px !important;
        font-weight: 400 !important;
    }

    .lp-ft-category-link {
        font-size: 13px !important;
        font-weight: 500 !important;
        color: white !important;
        text-decoration: none !important;
    }

    .lp-ft-social-item {
        text-align: start !important;
        margin: 10px 0px !important;
    }

    .lp-ft-social-link {
        margin-left: 10px !important;
    }

    .lp-ft-social-link1 {
        margin-left: 0px !important;
    }

    .lp-footer-ul-points-item3 {
        margin-top: 50px !important;
    }

    .lp-ft-contact-btn-container {
        justify-content: center;
        margin-top: 10px;
    }

    .lp-ft-overlap-text-main-item {
        text-align: center !important;
        margin-bottom: 20px !important;
    }
}

@media screen and (max-width: 1199px) {
    .lp-ft-company-info {
        padding-top: 25px !important;
    }
}