.ChromeExtension-main-page {
  /* height: 100vh; */
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 70px 0;
}
.ChromeExtension-inner-div {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.ChromeExtension-download-button-chrome-logo {
  width: 20px;
}
.ChromeExtension-text-content {
  display: flex;
  flex-direction: column;
  width: 80%;
}
.ChromeExtension-text-content-top-text {
  color: #6a097d;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  font-weight: 600;
  line-height: 100%; /* 18px */
  letter-spacing: 3.78px;
}
.ChromeExtension-text-content-title {
  color: #000;
  text-align: center;
  font-size: 30px;
  font-weight: 600;
}
.ChromeExtension-text-content-discription {
  color: #7a7a7a;
  text-align: center;
  font-size: 15px;
  margin-top: 20px;
  font-weight: 600;
}
.ChromeExtension-download-button-perent {
  display: flex;
  justify-content: center;
  display: flex;
  width: 230px;
  height: 50px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-shrink: 0;
  color: #fff;
  margin: 60px 0px 5px 0px;
  border-radius: 5px;
  background: #6a097d;
  cursor: pointer;
}
.ChromeExtension-used-by-x-users {
  margin-bottom: 55px;
  color: #7a7a7a;
}
.ChromeExtension-download-button {
  display: flex;
  width: 100%;
  height: 57px;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
}

.ChromeExtension-download-subtext {
  font-size: 15px;
  font-weight: 500;
  color: #7A7A7A;
}

.ChromeExtension-below-part {
  display: flex;
  flex-direction: row;
  /* width: 80%; */
  justify-content: center;
  column-gap: 100px;
}
.ChromeExtension-below-part-card {
  /* columns: 30px; */
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ChromeExtension-below-part-card-image {
  /* width: 350px;
  height: 513px; */
  /* flex-shrink: 0; */
  /* border-radius: 20px; */
  /* background: #fdf9ff; */
}
.ChromeExtension-below-part-card-title {
  color: #000;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  margin: 30px 0px 10px 0px;
}
.ChromeExtension-below-part-card-discription {
  color: #7a7a7a;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  width: 70%;
}



@media (max-width: 1200px) {
.ChromeExtension-below-part{
  width: 100%;
}
.ChromeExtension-below-part-card{
  width: 30%;
}
.ChromeExtension-below-part-card-image{
  width: 250px;
  height: 400px;
}
}


@media (max-width: 800px) {
  .ChromeExtension-below-part {
    flex-direction: column;
    align-items: center;
  }
  .ChromeExtension-below-part-card{
    width: 50%;
  }
  .ChromeExtension-below-part-card-image{
    width: 300px;
    height: 500px;
  }
  .ChromeExtension-below-part-card{
    margin-bottom: 40px;
  }
  .ChromeExtension-below-part-card-discription{
    width: 90%;
  }
  .ChromeExtension-text-content{
    width: 90%;
  }
  .ChromeExtension-text-content-title{
    font-size: 24px;
  }
  .ChromeExtension-text-content-discription{
    font-size: 14px;
  }
}

@media (max-width: 650px) {
  .ChromeExtension-below-part {
    flex-direction: column;
    align-items: center;
  }
  .ChromeExtension-below-part-card{
    width: 50%;
  }
  .ChromeExtension-below-part-card-image{
    width: 300px;
    height: 500px;
  }
  .ChromeExtension-below-part-card{
    margin-bottom: 40px;
  }
  .ChromeExtension-below-part-card-discription{
    width: 90%;
  }
  .ChromeExtension-text-content{
    width: 90%;
  }
  .ChromeExtension-text-content-title{
    font-size: 24px;
  }
  .ChromeExtension-text-content-discription{
    font-size: 14px;
  }
}




