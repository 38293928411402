.arp-head-main-item {
  min-height: 200px !important;
  background-color: #FDF9FF !important;
  margin-top: 70px !important;
  padding: 50px 0px !important;
}

.arp-main-head-item {
  text-align: center !important;
  margin: 0 auto !important;
}

.arp-main-head {
  color: #6A097D !important;
  font-size: 40px !important;
  font-weight: 600 !important;
}

.arp-subtitle-text-item {
  margin: 10px auto !important;
  text-align: center !important;
}

.arp-subtitle-text {
  color: #7A7A7A !important;
  font-size: 18px !important;
  font-weight: 500 !important;
}

.arp-search-item {
  margin: 60px auto 0px !important;
}

.arp-searchbar {
  border-radius: 10px !important;
  background: #FFF !important;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25) !important;
  font-size: 17px !important;
  font-weight: 600 !important;
  color: black !important;
}