.fb-navbar {
    padding: 0px 260px !important;
    background-color: white !important;
}

.fb-reinstall-wz-btn {
    border-radius: 5px !important;
    background: #6A097D !important;
    color: #FFF !important;
    text-transform: capitalize !important;
}