.ts-form-item {
    margin: 0 auto !important;
    padding: 20px 40px !important;
    border-radius: 10px !important;
}



@media screen and (min-width : 0px) and (max-width : 330px) {
    .google-recaptcha {
        transform: scale(0.6);
        /* Adjust the scale factor as needed */
        transform-origin: 0 0;
    }
}

@media screen and (min-width : 330px) and (max-width : 370px) {
    .google-recaptcha {
        transform: scale(0.7);
        /* Adjust the scale factor as needed */
        transform-origin: 0 0;
    }
}

@media screen and (min-width : 370px) and (max-width : 376px) {
    .google-recaptcha {
        transform: scale(0.75);
        /* Adjust the scale factor as needed */
        transform-origin: 0 0;
    }
}

@media screen and (min-width : 370px) and (max-width : 400px) {
    .google-recaptcha {
        transform: scale(0.8);
        /* Adjust the scale factor as needed */
        transform-origin: 0 0;
    }
}