* {
    margin: 0;
    padding: 0;
    box-sizing: 'border-box'
}

.gitex-navbar.wz-header {
    background: white !important;
}

.gitex-navbar-content-divider {
    height: 65px;
    border-bottom: 1px solid #CDCDCD !important;
}

.gx-main-container {
    width: 100%;
    padding-top: 50px;
    background-image: url('../../assests/Waves.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: end center;
}

.gx-white-section-container {
    width: 75%;
    margin-inline: auto !important;
    padding: 50px 0;
}

.gx-white-section {
    /* width: 90%; */
    max-width: 700px;
    font-weight: 600;
}

.gx-small-heading {
    font-size: 15px;
    color: #6A097D;
    padding-bottom: 10px;
    font-weight: 600 !important;
}

.gx-main-heading {
    font-size: 60px;
    padding: 10px 0;
    font-weight: 500;
}

.gx-main-sub-text {
    font-size: 18px;
    color: #888888;
    font-weight: 500;
}

.gx-colored-section-container {
    background-color: #F9EDFF;
    background-image: url('../../assests/Waves.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: end center;
    position: relative;
    /* height: 50vh; */
}

.gx-cards-wrapper {
    width: 75% !important;
    margin: auto;
    flex-direction: row;
    column-gap: 30px;
    justify-content: space-between;
}

.gx-join-event-card {
    background-color: #fff;
    margin: 50px 0 !important;
    padding: 40px !important;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    border-radius: 20px 0px 20px 0px;
    height: 480px;
    min-height: fit-content;
    width: 100%;
}

.gx-card-heading {
    padding: 15px 0;
    font-size: 26px !important;
    font-weight: 600 !important;
}

.gx-card-description {
    font-weight: 500;
    font-size: 17px;
    padding: 10px 0;
}

.gx-form-card-container {
    background-color: rgba(250, 250, 250, 1);
    margin: 50px 0 !important;
    height: 700px;
    padding: 40px !important;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    border-radius: 20px;
}

@media (max-width: 1500px) {
    .gx-white-section-container {
        width: 85% !important;
    }

    .gx-cards-wrapper {
        width: 85% !important;
        margin: auto !important;
    }
} 

/* Form */

.gi-form-main-item{
    background-color: #FAFAFA !important;
    padding: 40px !important;
    border-radius: 20px !important;
    margin: 50px 0 !important;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
}

.gi-form-global-label-item{
    margin: 5px 0px !important;
}

.gi-form-global-label{
    color: #000 !important;
    font-size: 15px !important;
    font-weight: 500 !important;
}


.gi-form-textfields div{
    border-radius: 5px !important;
    background: #FFF !important;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.25) !important;
}

.gi-submit-btn{
    text-transform: none !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    background-color: #F7E9FF !important;
    color: #6A097D !important;
    padding: 6px 40px !important;
    box-shadow: none !important;
    margin-top: 10px !important;
}

.gi-global-form-fields-item{
margin: 10px 0px !important;
}

.gi-sub-head-item{
    margin: 10px 0px !important;
}

.MuiFormHelperText-root {
    margin: 0 5px !important;
}

.Mui-focused fieldset{
    border-color: rgba(106, 9, 125, 0.7) !important;
}
/* Form */



@media (max-width: 1200px) {
    .gx-join-event-card {
        margin-top: 0 !important;
    }

    .gx-cards-wrapper {
        flex-direction: column-reverse !important;
    }
}

@media (max-width: 600px) {
    .gx-white-section-container {
        width: 85%;
    }

    .gx-cards-wrapper {
        width: 85% !important;
    }

    .gx-main-heading { 
        font-size: 50px;
    }

    .gx-join-event-card {
        height: 550px;
    }
}

@media (max-width: 500px) {
    .gx-white-section-container {
        width: 90%;
    }

    .gx-cards-wrapper {
        width: 90% !important;
    }
}