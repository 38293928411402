.lp-main-text-animation-container {
  /* height: 100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.lp-main-text-animation-grid-container {
  /* height: 91vh; */
  justify-content: space-between;
  /* margin: 50px 0px 0px 0px; */
  margin-top: 65px;
  margin-bottom: 150px;
  /* padding: 55px 0px 85px 0px; */
  padding: 55px 0px 0px 0px;
  background: #F9EDFF;
  border-radius: 20px;
  align-items: center;
  display: flex;
  flex-direction: column !important;
  width: 90% !important;
  flex-wrap: nowrap !important;
}

.lp-text-animation {
  width: 90%;
}

.wz-head-points-text {
  color: #252525 !important;
  font-feature-settings: 'clig' off, 'liga' off !important;
  font-size: 30px !important;
  font-weight: 600 !important;
  margin: 7px 0px !important;
}

.wz-lp-head-twf-btn-item {
  padding: 35px 0px 60px 0px !important;
}

.wz-lp-head-twf-btn {
  border-radius: 5px !important;
  background: #6A097D !important;
  text-transform: capitalize !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  padding: 10px 20px !important;
}


.wz-video {
  border-radius: 20px;
  width: 100%;
  height: 80vh;
}

.pageone {
  /* height: 100vh !important; */
}

.HomePage-sub-text {
  margin-top: 10px !important;
}

.wz-lp-head-key-point {
  font-size: 16px !important;
  text-transform: capitalize !important;
  background-color: transparent;
  color: #000 !important;
  font-weight: 600 !important;
  border-radius: 5px;
  box-shadow: 0px 0px 2px 0px #616161 !important;
  padding: 8px 15px;
  width: 188px;
}

@media (max-width: 1468px) and (min-width: 1370px) {
  .wz-lp-head-key-point {
    font-size: 15px !important;
    width: 177px;
  }
}

@media (max-width: 1200px) {
  .wz-head-points-text {
    font-size: 25px !important;
  }
}

@media (max-width: 950px) {
  .wz-head-points-text {
    font-size: 22px !important;
  }
}

@media (max-width: 700px) {
  .wz-head-points-text {
    font-size: 20px !important;
  }
}

@media (max-width: 600px) {
  .lp-main-text-animation-grid-container {
    /* height: 93vh; */
  }
}

@media (max-width: 500px) {

  .wz-head-points-text {
    font-size: 18px !important;
  }

  .wz-video {
    height: 40vh;
  }

  .HomePage-sub-text {
    margin-top: 0px !important;
  }

  .wz-lp-head-key-point {
    font-size: 13px !important;
  }

  .wz-lp-head-twf-btn-item {
    padding-bottom: 40px !important;
  }
}

.wz-lp-video-main-item {
  margin: 0px auto !important;
  /* background: rgb(253, 249, 255); */
  /* padding: 40px !important; */
  /* background: linear-gradient(0deg, rgba(253, 249, 255, 1) 0%, rgba(183, 15, 194, 1) 100%); */
  border-radius: 20px !important;
  width: 90%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1150px) {
  .lp-main-text-animation-grid-container {
    padding-top: 45px;
  }
}

@media (max-width: 900px) {

  .lp-main-text-animation-grid-container {
    width: 90% !important;
  }

  .wz-lp-video-main-item {
    height: auto;
    margin: 50px auto !important;
  }

}

@media (max-width: 700px) {
  .lp-main-text-animation-grid-container {
    padding-top: 25px;
  }
}

@media (max-width: 600px) {
  .lp-main-text-animation-grid-container {
    margin-top: 56px;
  }
}

@media (max-width: 500px) {
  .lp-main-text-animation-grid-container {
    padding-top: 18px;
  }

  .wz-lp-head-key-point {
    width: 140px;
    padding: 8px 0px;
    text-wrap: nowrap;
    font-size: 12px !important;
  }
}

/* @media (max-width: 1300px) {
  .HomePage-sub-text {
    margin-top: 0px !important;
  }
}

@media (max-width: 900px) {
  .HomePage-sub-text {
    margin-top: 10px !important;
  }
} */

.lp-gitex-banner-container {
  background: linear-gradient(30deg, #0A6ADD 20%, #6A097D 55%) !important;
  margin-top: 20px;
  width: 90%;
  border-radius: 20px 0 20px 0;
  padding: 0 50px;
  margin: auto;
  justify-content: space-between !important;
  height: 100px;
}

.lp-gitex-image {
  width: 400px;
  line-height: 0;
  margin: auto;
}

.lp-gitex-banner-text {
  color: white;
  padding-top: 10px;
  font-size: 18px !important;
}

.lp-gitex-banner-ele-wrapper {
  justify-content: space-between;
}

.lp-gitex-banner-desk-info {
  justify-content: center;
  color: white;
}

.lp-gitex-banner-desk-info h6 {
  font-size: 20px;
}

@media (max-width: 1200px) {
  .lp-gitex-image {
    width: 350px;
    line-height: 0;
    margin: auto;
  }

  .lp-gitex-banner-container {
    height: fit-content;
  }

  .lp-gitex-banner-text {
    font-size: 16px !important;
    font-weight: 600;
    padding-bottom: 5px;
  }

  .lp-gitex-banner-ele-wrapper {
    justify-content: center;
  }
}

@media (max-width: 950px) {
  .lp-gitex-banner-text {
    margin-top: -18px !important;
    padding-top: 0px;
  }
}

@media (max-width: 1000px) {
  .lp-gitex-banner-container {
    padding: 0 20px !important;
  }
}

@media (max-width: 920px) {
  .lp-gitex-banner-container {
    flex-direction: column !important;
  }
}

@media (max-width: 600px) {
  .lp-gitex-image {
    width: 300px;
  }

  .lp-gitex-banner-text {
    font-size: 11px !important;
    font-weight: 500;
  }

  .lp-gitex-banner-container {
    padding: 0 5px !important;
  }

  .lp-gitex-banner-desk-info span {
    font-size: 12px;
  }

  .lp-gitex-banner-desk-info h6 {
    font-size: 16px;
  }

}