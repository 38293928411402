.tryourproduct-main {
  background: #F9EDFF;
  width: 100%;
  display: flex;
  /* height: 950px; */
  min-height: 90vh;
  height: auto;
  margin: 00px auto;
  justify-content: center;
}

.tryoutproducts-innerbox {
  background: #F9EDFF;
  width: 43%;
  display: flex;
  /* height: 800px; */
  height: auto;
  margin: 60px auto;
  text-align: center;

  /* justify-content: center; */
  flex-direction: column;
}
.tryourproducts-text {
  font-size: 30px;
  font-weight: 600;
  margin: 20px auto 40px auto;

}

.tryoutproducts-innerbox .MuiTabs-root {
  border-bottom: 1px solid #D6D6D6;
  margin-bottom: 20px;
}

.tryoutproducts-innerbox .MuiTab-root {
  font-weight: 600;
  font-size: 16px;
}

/* .tryoutproducts-innerbox .MuiTab-root:first-child {
  align-items: start;
  padding-left: 0;
}

.tryoutproducts-innerbox .MuiTab-root:last-child {
  align-items: end;
  padding-right: 0;
} */

.tryout-our-products-tab .Mui-selected {
  color: #6a097d !important;
}

.tryoutproducts-top-text{
  color: #6A097D;
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 18px */
  letter-spacing: 3.78px;
}

/* MEDIA QUARY */
@media (max-width: 1500px) {
  .tryoutproducts-innerbox {
    width: 55%;
  }
}
@media (max-width: 1200px) {
  .tryoutproducts-innerbox {
    width: 60%;
  }
}
@media (max-width: 1200px) {
  .tryourproducts-text {
    font-size: 25px;
    margin: 40px auto;
  }
}

@media (max-width: 900px) {
  .tabpanel-tab-and-button {
    flex-direction: column;
  }
  .tryoutproducts-innerbox {
    width: 75%;
  }
}
@media (max-width: 650px) {
  .tryoutproducts-innerbox {
    width: 90%;
  }
  .tryoutproducts-innerbox .MuiTab-root {
    font-size: 13px;
    min-width: 10px !important;
  }

}
@media (max-width: 500px) {
  .tryourproducts-text {
    margin: 25px auto;
    font-size: 16px;
    
  }

  .tryoutproducts-innerbox .MuiTabs-scroller {
    /* overflow: scroll !important; */
    /* overflow-y: hidden; */

  }
}
