.articlecard-main-div{
  height: 200px;
  width: 270px;
  background-color: white;
  border: 1px solid black;
  border-radius: 10px;
}
.articlecard-inner-div{
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

