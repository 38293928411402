/* Support */

.sp-links-section-item {
  margin: 0px 0px !important;
}

.sp-link-list-item {
  margin: 50px auto !important;
  padding: 15px 5px !important;
  border: 1px solid #e5e7eb !important;
  border-radius: 10px !important;
}

.sp-popular-artical-text-item {
  padding-left: 20px;
  padding-bottom: 10px;
  padding-right: 10px;
}

.sp-popular-artical-text {
  font-size: 28px;
  font-weight: 600;
  color: #6A097D;
}

.sp-link-items {
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-decoration: none;
}

.sp-link-text {
  color: black;
  font-size: 16px;
  font-weight: 500;
}

.sp-links-main-item {
  padding: 10px 20px !important;
}

.sp-links-main-item:hover {
  background-color: #FBF4FF;
  border-radius: 12px;
}

/* Support */