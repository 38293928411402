/* .brws-main-item {
    margin-top: 65px !important;
} */

.brws-download-img-item {
    background-image: url('../../assests/ArticalBg.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    /* height: 800px; */
    width: 100%;
    margin: 65px auto !important;
    padding: 120px 0px;
}

.brws-head-iframe-item-img {
    /* width: 630px;
    height: 600px; */
    /* max-width: 90%; */
    width: 100%;
    max-width: 600px;
    display: flex;
    justify-content: center;
    margin: auto;
}

.brws-download-img-sub-item {
    margin: 0 auto !important;
}

.brws-download-img-container {
    justify-content: space-between;
}

.brws-head-text-item {
    display: flex;
    align-items: center;
}

.brws-head-text {
    display: flex;
    flex-direction: column;
    color: #6A097D !important;
    font-size: 40px !important;
    font-weight: 600 !important;
}

.brws-head-text-black {
    color: #000 !important;
}

.brws-download-btn {
    border-radius: 10px !important;
    background: #6C0D7F !important;
    text-transform: none !important;
    padding: 10px 15px !important;
    font-size: 18px !important;
    font-weight: 600 !important;
}

.brws-gif-content-container {
    justify-content: center;
    gap: 15px;
}

.brws-head-text-item {
    margin: 0px 0px 10px !important;
}

.brws-head-subtitle-text-item {
    margin: 15px 0px !important;
}

.brws-head-subtitle-text {
    font-size: 18px !important;
    font-weight: 500 !important;
    color: #7A7A7A !important;
}

.brws-download-btn-item {
    margin: 20px 0px 0px !important;
}

@media (max-width: 900px) {
    .brws-download-img-item {
        margin: 65px auto 0 auto !important;
    }
}


@media only screen and (min-width: 320px) and (max-width: 899px) {

    .brws-head-iframe-item {
        margin: 30px 0px 50px !important;
    }

}
@media only screen and (min-width: 320px) and (max-width: 499px) {

    .brws-head-text {
        font-size: 18px !important;
    }
    .brws-head-subtitle-text {
        font-size: 13px !important;
    }
     .brws-download-img-item {
        padding: 23px 0 !important;
    }
    .brws-download-btn {
        font-size: 15px !important;
        padding: 7px 15px !important;
    }
    .brws-head-subtitle-text-item {
        margin: 0px 0px 10px 0px !important;
    }
    .brws-download-btn-item {
        margin: 10px 0px 0px !important;
    }
}