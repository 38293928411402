.ap-info-main-container {
    background-color: white;
}

.ap-info-ext-item {
    margin: 0 auto !important;
}

.ap-info-ext-container {
    justify-content: space-between;
}

.ap-info-head-text1 {
    font-size: 30px !important;
    font-weight: 600 !important;
}

.ap-info-head-writewiz-text {
    font-size: 30px !important;
    font-weight: 600 !important;
    color: #6A097D !important;
}

.ap-info-ext-head-text {
    color: black !important;
    font-size: 30px !important;
    font-weight: 600 !important;
}

.ap-info-ext-head-text2 {
    font-size: 30px !important;
    font-weight: 600 !important;
}

.ap-info-ext-peragraph-item {
    margin-top: 20px !important;
    color: #7A7A7A !important;
    font-size: 18px !important;
    font-weight: 500 !important;
}

.ap-info-ext-btn {
    border-radius: 5px !important;
    background: #6A097D !important;
    color: white !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    align-items: center !important;
    justify-content: space-between !important;
    text-transform: capitalize !important;
    padding: 10px 10px !important;

}

.ap-info-ext-btn-item {
    display: flex !important;
    align-items: center !important;
    margin-top: 50px !important;
}

.ap-info-card-item {
    margin: 80px auto 0px !important;
}

.ap-info-card {
    justify-content: center !important;
    text-align: center !important;
}

.ap-info-card-main-item {
    border-radius: 10px !important;
    border: 1px solid #D9D9D9 !important;
    padding: 0px 25px 20px !important;
    margin: 0px 10px 50px !important;
}

.ap-info-card-main-item:hover {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2) !important;
    transform: scale(1.05) !important;
}

.ap-card-item {
    text-align: center !important;
}

.ap-card {
    text-align: center !important;
    margin: -35px auto 20px !important;
}

.ap-info-alternative-image {
    height: 400px !important;
    width: auto !important;
    text-align: center !important;
    margin: 0 auto !important;
}

.ap-info-card-head-item {
    text-align: center !important;
    margin: 0px 0px 10px !important;
    color: black !important;
    font-size: 25px !important;
    font-weight: 600 !important;
}

.ap-info-card-details-item {
    text-align: center !important;
    margin: 10px 0px !important;
}

.ap-info-card-main-container {
    justify-content: start;
}

.ap-info-ext-header-item {
    text-align: center !important;
    margin: 0px auto 100px !important;
}



@media only screen and (min-width: 320px) and (max-width: 899px) {

    .ap-info-ext-header-item {
        margin-bottom: 50px !important;
    }

    .ap-info-card-img-item {
        margin-top: 80px !important;
    }
}

@media only screen and (min-width: 320px) and (max-width: 599px) {

    .ap-info-head-text1 {
        font-size: 25px !important;
        font-weight: 600 !important;
    }

    .ap-info-ext-head-text {
        font-size: 25px !important;
        font-weight: 600 !important;
    }

    .ap-info-ext-head-text2 {
        font-size: 25px !important;
        font-weight: 600 !important;
    }

    .ap-info-ext-peragraph-item {
        font-size: 15px !important;
        font-weight: 500 !important;
    }

    .ap-info-head-text2 {
        font-size: 25px !important;
        font-weight: 600 !important;
    }

    .ap-info-alternative-image {
        height: 320px !important;
        width: auto !important;
        text-align: center !important;
        margin: 0 auto !important;
    }


}




@media only screen and (min-width: 1200px) and (max-width: 1252px) {

    .ap-info-card-main-item {
        margin: 0px 9px 50px !important;
    }

}