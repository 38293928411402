.cw-info-main-item {
    margin: 100px auto 20px !important;
}

.cw-info-grid-main-item {
    max-width: 1300px !important;
    margin: 0 auto !important;

}

.cw-info-grid-sub-item {
    margin: 0 auto !important;
}

.cw-info-main-container {
    justify-content: space-between;
}

.cw-info-multiplatform-item {
    background-color: #f4f7f9;
    border-radius: 8px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
    padding: 15px 0px 0px 0px;
    overflow: hidden;
    position: relative;
}

.cw-info-main-head-text {
    font-size: 35px !important;
    font-weight: 500 !important;
}

.cw-info-multiplatform-text {
    font-size: 15px;
    font-weight: 600;
}

/* .cw-info-earth-img {
    width: 200px;
    height: auto;
    margin-bottom: -15px;
    margin-right: -15px;
} */

.cw-info-img-item {
    text-align: center;
    position: relative;
    overflow: hidden;
}

.cw-info-slash-card-item {
    background-color: #f4f7f9;
    border-radius: 8px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
    padding: 15px 15px 0px;
    overflow: hidden;
}

.cw-info-slash-img {
    width: 200px;
    height: auto;
    margin-top: 20px;
    margin-bottom: -12px;
    vertical-align: middle;
}

.cw-info-slash-main-item {
    margin: 20px auto !important;
}

.cw-info-slash-main-container {
    justify-content: space-between;
}

.cw-info-reduce-card-item {
    background-color: #F7E9FF;
    border-radius: 8px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
    padding: 15px 15px 0px;
    overflow: hidden;
}

.cw-infor-reduce-img {
    width: 450px;
    height: auto;
    overflow: hidden;
}

.cw-info-partner-main-item {
    margin: 20px auto !important;
}

.cw-info-partner-main-container {
    justify-content: space-between;
}

.cw-info-partner-card-item {
    border-radius: 8px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
    padding: 15px 15px 0px;
    overflow: hidden;
    background-color: #f4f7f9;
}

.cw-info-sub-text {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #1F384C !important;
}

.cw-info-guage-img {
    width: 245px;
    height: 170px;
    overflow: hidden;
    margin-top: 0px;
    margin-bottom: -10px;
    vertical-align: middle;
}

.cw-info-partner-text {
    font-size: 15px;
    font-weight: 600;
}

.cw-info-day-card-item {
    background-color: #F4F7F9;
    border-radius: 8px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
    padding: 15px 15px 0px;
    overflow: hidden;
}

.cw-info-clip-img {
    width: 250px;
    height: auto;
    overflow: hidden;
}

.cw-info-meta-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    vertical-align: middle !important;
    display: flex !important;
    align-items: center;
    overflow: hidden;
}

.cw-info-clip-item {
    text-align: end;
}

.cw-info-clipboard-main-item {
    margin: 20px auto !important;
}

.cw-info-clip-head-text {
    font-size: 30px !important;
    font-weight: 500 !important;
    color: black;
}

.cw-info-clip-head-highlioght-text {
    font-size: 30px !important;
    font-weight: 500 !important;
    color: rgba(31, 56, 76, 0.7) !important;
}

.cw-info-clipboard-main-container {
    align-items: center;
    justify-content: space-between;
}


.hover-text {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: start;
    justify-content: center;
    color: #000;
    font-size: 15px;
    font-weight: 500;
    color: #1F384C;
    transition: opacity 0.5s ease;
    text-align: start;
}

.cw-info-earth-img {
    width: 200px;
    height: auto;
    margin-bottom: -15px;
    margin-right: -15px;
    vertical-align: middle;
}

.cw-info-reduce-card-item {
    position: relative;
    overflow: hidden;
}

.cw-info-reduce-img {
    width: 100%;
    position: absolute;
    bottom: -100%;
    transition: all 0.5s ease-in-out;
}