@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Roboto:wght@500&display=swap");

/* .redirect-to-dashboard .tabpanel-main-div {
  background: rgba(0, 0, 0, 0.25) !important;
  cursor: none;
  z-index: -1;
} */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7); /* Semi-transparent background color */
  z-index: 1000; /* Adjust the z-index as needed */
  pointer-events: auto; /* Prevent interaction with elements underneath */
}

.tabpanel-inner-box {
  /* height: 600px; */
  height: auto;
  width: 100%;
  margin: 0px 0px 0px 0px;
  background-color: rgba(253, 249, 255, 1);
}

.tabpanel-text-box {
  height: 170px;
  width: 100%;
  padding: 30px;
  font-size: 18px;
  margin: 0px auto 0px auto;
  font-family: "Open Sans", sans-serif !important;
  background-color: white;
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
  border: none;
  line-height: 160%;
  font-weight: 400;
  font-style: normal;
  outline: none;
  resize: none;
  letter-spacing: 0.72px;
  color: #000;
  padding-bottom: 5px;
}

.button-at-bottom-of-textarea {
  display: flex;
  background-color: white;
  justify-content: space-between;
  align-items: end;
  width: 100%;
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  padding: 10px 30px 30px 30px;
}
.button-at-bottom-of-textarea-without-border-radius {
  display: flex;
  background-color: white;
  justify-content: space-between;
  align-items: end;
  width: 100%;
  padding: 10px 30px 30px 25px;
}

.tabpanel-text-panel-div {
  display: flex;
  flex-direction: column;
  border-radius: 10px !important;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}
.tabpanel-text-panel-div-without-bottom-border-radius {
  display: flex;
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  flex-direction: column;
}

.tab-panel-suggetion-box {
  background-color: white;
  height: fit-content;
  max-height: 340px;
  margin-top: 2px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 0 0 15px 15px;
}

.tabpanel-tab-and-button {
  display: flex;
  flex-direction: row;
  height: 60px;
  /* border-bottom: 2px solid #f1f1f1; */
  justify-content: space-between;
  padding: 0px 13px;
  width: 100%;
}
.tabpanel-tab-and-button .MuiTabs-root {
  font-size: 18px !important;
}

.tabpanel-tab-and-button .Mui-selected {
  color: #6a097d;
}
.tabpanel-tab-and-button .MuiTabs-root {
  width: 48%;
  border-bottom: none !important;
  height: 62px;
}

.tabpanel-tab-and-button .MuiTabs-scroller {
  height: 59px;
}

.use-suggetion-button {
  border-radius: 5px;
  background: #fff;
  /* box-shadow: 0px 0px 2px 0px #6A097D;*/
  font-weight: 600;
  height: 40px;
  border: none;
  margin-left: auto;
  color: #6a097d;
  height: 100%;
  font-weight: 600;
  width: 275px;
  display: flex;
  justify-content: center;
  font-size: 18px;
  align-items: center;
  margin-top: 5px;
  cursor: pointer;
  justify-content: end;
}

.use-suggetion-button-inside-textbox {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 2px 0px #6a097d;
  /* margin: 10px 0px; */
  height: 50px;
  border: none;
  color: #6a097d;
  /* color: black; */
  font-weight: 600;
  width: 275px;
  display: flex;
  justify-content: center;
  font-size: 17px;
  align-items: center;
}

.suggetion-box-inner-box {
  padding: 0px 20px;
  overflow-y: auto;
  scroll-snap-type: mandatory;
}

.map-suggetion-box {
  /* display: flex; */
  flex-direction: column;
  border-bottom: 1px;
  height: fit-content;
  max-height: 260px;
  padding: 13px 0 30px 0;
  margin-left: 5px;
}

.single-sentence {
  margin: auto 0px;
  display: flex;
  font-weight: 600;
  font-size: 15px;
  justify-content: space-between;
  align-items: center;
  /* justify-content: center; */
  text-align: left !important;
  /* height: 50px !important; */
  border-bottom: 1px solid rgba(237, 237, 237, 1);
  /* max-height: 50px; */
  min-height: 40px;
  padding: 5px 0px;
}
.single-sentence:last-child {
  border-bottom: none;
  padding-bottom: 20px;
}

.single-sentence-skeleton {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  height: 50px !important;
  border-bottom: 1px solid rgba(237, 237, 237, 1);
}

.tabpanel-tab-and-button .MuiTabs-scroller .MuiTabs-flexContainer {
  height: 60px;
  /* width: 100px; */
}

.single-sentence-text-selected {
  /* color: #6a097d !important; */
  color: black !important;
  width: 88%;
  font-weight: 500 !important;
}
.single-sentence-text {
  width: 85%;
  /* color: black !important; */
  color: grey !important;
  font-weight: 500 !important;
}
.double-sentence{
  margin: auto 0px;
  display: flex;
  font-weight: 600;
  font-size: 15px;
  justify-content: space-between;
  align-items: center;
  /* justify-content: center; */
  text-align: left !important;
  /* height: 105px !important; */
  border-bottom: 1px solid rgba(237, 237, 237, 1);
  /* max-height: 105px; */
  min-height: 100px;
  padding: 5px 0px;
}

.double-sentence:last-child {
  padding-bottom: 25px;
}

.text-area-left-side-button {
  height: 45px;
  padding: 0 20px 0 15px;
  color: #fff;
  display: flex;
  column-gap: 10px !important;
  font-family: "Open Sans", sans-serif !important;
  justify-content: center;
  font-size: 15px;
  align-items: center;
  background: #6a097d;
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: pointer;
}

.text-area-left-side-button:disabled {
  cursor: default;
}

.character-count {
  display: flex;
  justify-content: end;
  color: red;
  padding-right: 30px;
  height: 24px;
  /* align-items: center; */
  background: #fff;
}

.creditExpired-container {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 600;
  background-color: #fff;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 0 4 0 rgba(0, 0, 0, 0.25);
}

.creditExpired-text {
  margin-top: 50px;
}

.creditExpired-subtext {
  margin-top: 10px;
  margin-bottom: 45px;
}

.creditExpired-container button {
  margin-bottom: 50px;
  font-size: 18px;
  font-weight: 500;
}

.creditExpired-container button img {
  margin-bottom: 0 !important;
}

@media (max-width: 1700px) {
  .tabpanel-tab-and-button .MuiTabs-root {
    width: 60%;
  }
}

@media (max-width: 1500px) {
  .tabpanel-tab-and-button .MuiTabs-root {
    width: 50%;
  }
}

@media (max-width: 1300px) {
  .tabpanel-tab-and-button .MuiTabs-root {
    width: 60%;
  }
}

@media (max-width: 1000px) {
  .tabpanel-tab-and-button .MuiTabs-root {
    width: 65%;
  }
}


@media (max-width: 900px) {
  .tabpanel-tab-and-button .MuiTabs-root {
    width: 80%;
  }

  .tabpanel-tab-and-button {
    flex-direction: column;
    height: 78px;
  }
  .use-suggetion-button {
    margin-top: 0;
  }
}
@media (max-width: 900px) {
  .use-suggetion-button-inside-textbox {
    height: 50px;
    font-weight: 400;
    width: 275px;
    display: flex;
    margin-left: 10px !important;
    justify-content: center;
    font-size: 14px;

    align-items: center;
  }

  .single-sentence-text-selected {
    width: 80%;
  }
}
@media (max-width: 650px) {
  .tabpanel-text-box {
    font-size: 14px;
  }

  .use-suggetion-button {
    font-size: 15px !important;
  }

  .single-sentence {
    margin: auto 0px;
    display: flex;
    font-weight: 600;
    font-size: 14px;
    justify-content: space-between;
    align-items: center;
    text-align: left !important;
    /* justify-content: center; */
    /* height: 50px !important; */
    border-bottom: 1px solid rgba(237, 237, 237, 1);
    /* max-height: 60px; */
    min-height: 50px;
  }
  .single-sentence-text {
    width: 80% !important;
  }
  .single-sentence-text-selected {
    width: 80% !important;
    color: #6a097d !important;
  }
  .tabpanel-tab-and-button .MuiTabs-root {
    font-size: 15px !important;
    justify-content: center;
    align-items: center;
  }
  .tabpanel-tab-and-button {
    flex-direction: column;
    /* height: 105px;
    margin-bottom: 20px; */
    /* align-items: center; */
  }
  .tabpanel-tab-and-button .MuiTabs-root {
    height: 60px;
  }
  .use-suggetion-button {
    height: 30%;
    font-size: 16px;
  }
  .map-suggetion-box {
    padding-top: 0;
  }

  .tab-panel-suggetion-box {
    border-radius: 0px 0px 10px 10px;
    background: #fff;
  }
  .tabpanel-text-box {
    height: 220px;
  }
  .text-area-left-side-button {
    height: 40px;
    border: none;
    outline: none;
    border: none;
  }
  .use-suggetion-button-inside-textbox {
    height: 40px;
    font-size: 13px;
  }
}

@media (max-width: 500px) {
  .tabpanel-tab-and-button .MuiTabs-root {
    width: 100%;
  }
}