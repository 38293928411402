.cw-head-text-main-item {
    margin: 0 auto !important;
}

.cw-head-text-item {
    text-align: center !important;
    margin: 10px auto !important;
}

.cw-head-btn-item {
    text-align: center !important;
    margin: 50px auto !important;
}

.cw-head-free-btn {
    background-color: #6A097D !important;
    color: white !important;
    font-size: 17px !important;
    font-weight: 500 !important;
    border-radius: 8px !important;
    text-transform: none !important;
    padding: 10px 25px !important;
}

.cw-head-demo-btn {
    background-color: white !important;
    color: #6A097D !important;
    font-size: 17px !important;
    font-weight: 500 !important;
    border-radius: 8px !important;
    text-transform: none !important;
    padding: 10px 25px !important;
    border: 1px solid #6A097D !important;
}

.cw-head-wp-img-item {
    text-align: center;
    margin: 20px auto !important;
}

.cw-head-whstapp-image {
    width: 400px;
    height: 300px;
    object-fit: cover;
}