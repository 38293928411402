:root {
  --color-white: #fff;
  --time-animation: 0.6s;
}

@keyframes pulse {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(25px);
  }
  100% {
    transform: translateY(0px);
  }
}

.layout {
  width: 100%;
  height: calc(100vh * 3);
  position: relative;
}

.layout .section-stick {
  margin: auto;

  /* position: fixed; */
  right: 50px;
  bottom: 50px;

  display: flex;
  flex-direction: column;
  row-gap: 30px;
  align-items: center;
  justify-content: flex-start;

  z-index: 1000;
}

.layout .section-stick .stick,
.layout .section-stick .active {
  width: 13px;
  height: 62px;

  border: none;
  border-radius: 9px;
}



.layout section {
  width: 100%;
  height: 100vh;

  /* position: fixed; */
  top: 0;
  left: 0;
  right: 0;


  transform: translateY(100vh);
  transition: all var(--time-animation) ease-in-out;

  z-index: 0;
}

.layout section .home {
  display: flex;
  flex-direction: column;
  row-gap: 45px;
  align-items: center;
}

.layout section .home svg {
  animation: pulse 1.4s linear infinite normal;
}

.layout .s1 {
  background-color: #ffe594;
  transform: translateY(0);
  z-index: 4;
}

.layout .s2 {
  background-color: #ecc5ff;
  z-index: 3;
}

.layout .s3 {
  background-color: #befafe;
  z-index: 2;
}

.layout .s4 {
  background-color: #cefde8;
  z-index: 1;
}


/************************************* RESPONSIVE ***************************************/

/* MOBILE */
@media screen and (max-width: 576px) {
  body::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    -webkit-appearance: none;
  }

  .layout .section-stick {
    right: 20px;
    bottom: 20px;
    transform: scale(0.7);
  }

  .layout section {
    font-size: 40px;
  }

  #author {
    left: 20px;
    bottom: 20px;
    font-size: 18px;
  }

  #author svg {
    font-size: 30px;
  }
}
