.ap-ai-main-container {
    background-image: url(../../assests/ArticalBg.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 200px 0px 100px;
}

.ap-ai-head-text-item {
    margin: 10px auto !important;
}

.ap-ai-main-item {
    margin: 10px auto !important;
}

.ap-ai-sub-container {
    justify-content: space-between;
}

.ap-ai-subtitle-text-item {
    margin: 10px 0px !important;
}

.ap-ai-head-text {
    color: black !important;
    font-size: 30px !important;
    font-weight: 600 !important;
}

.ap-ai-highlight-head-text {
    color: #6A097D !important;
    font-size: 30px !important;
    font-weight: 600 !important;
}

.ap-ai-subtitle-text {
    color: #7A7A7A !important;
    font-size: 15px !important;
    font-weight: 500 !important;
}

.ap-ai-wz-btn-item {
    margin-top: 15px !important;
}

.ap-ai-wz-btn {
    background-color: #6A097D !important;
    color: white !important;
    text-transform: none !important;
    border-radius: 8px !important;
    padding: 10px 15px !important;

}

@media only screen and (min-width: 320px) and (max-width: 899px) {

    .ap-ai-main-container {
        padding: 10px 0px;
    }

    .ap-ai-partner-item1 {
        margin-bottom: 30px !important;
    }

    .ap-ai-partner-item2 {
        margin-top: 10px !important;
    }

    .ap-ai-head-text {
        color: black !important;
        font-size: 25px !important;
        font-weight: 600 !important;
    }

    .ap-ai-highlight-head-text {
        color: #6A097D !important;
        font-size: 25px !important;
        font-weight: 600 !important;
    }

    .ap-ai-subtitle-text {
        color: #7A7A7A !important;
        font-size: 14px !important;
        font-weight: 500 !important;
    }
}