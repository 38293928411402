.ap-intro-main-head-text {
    color: black !important;
    font-size: 40px !important;
    font-weight: 600 !important;
}

.ap-intro-main-head-highlighted-text {
    color: #6A097D !important;
    font-size: 40px !important;
    font-weight: 600 !important;
}

.alternative-page-intro-banner-maintext {
    text-align: center !important;
    margin: 20px auto 0px !important;
    word-wrap: break-word !important;
}

.alternative-page-intro-banner-subtext {
    margin: 0 auto !important;
}

.ap-intro-subtitle-text {
    color: #7A7A7A !important;
    font-size: 18px !important;
    font-weight: 500 !important;
}

.alternative-page-intro-banner-subtext {
    margin: 20px auto !important;
    text-align: center !important;
}


.alternative-page-intro-banner-buttoncontainer {
    margin: 10px auto 30px !important;
    text-align: center !important;
}

.ap-try-writewiz-btn {
    background-color: #6A097D !important;
    color: white !important;
    font-size: 20px !important;
    font-weight: 500 !important;
    text-transform: none !important;
    border-radius: 10px !important;
}

.an-p-card-maincontainer {
    display: flex;
    background-color: #FFF;
    min-height: 213px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.an-p-card-maincontainer:hover {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    transform: scale(1.05);
}

.an-p-card-logo-container {
    min-width: 60px;
    height: 60px;
    background-color: #F1D9FF;
    border-radius: 50%;
    margin: 40px 30px 0 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.an-p-card-text-area {
    display: flex;
    flex-direction: column;
}

.an-p-card-text-area h3 {
    color: #000;
    margin-top: 40px;
    margin-bottom: 0;
    font-family: Open Sans;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
}

.an-p-card-text-area p {
    margin: 20px 20px 40px 0;
    color: #7A7A7A;
    font-family: Open Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


@media only screen and (min-width: 320px) and (max-width: 599px) {

    .ap-intro-main-head-text {
        font-size: 24px !important;
        font-weight: 600 !important;
    }

    .ap-intro-main-head-highlighted-text {
        font-size: 24px !important;
        font-weight: 600 !important;
    }

    .ap-intro-subtitle-text {
        color: #7A7A7A !important;
        font-size: 15px !important;
        font-weight: 600 !important;
    }

    .ap-try-writewiz-btn {
        background-color: #6A097D !important;
        color: white !important;
        font-size: 15px !important;
        font-weight: 500 !important;
        text-transform: none !important;
        border-radius: 10px !important;
    }
}