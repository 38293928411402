:root {
  --selectedTabHeight: 120px;
  --tabHeight: 70px;
}


.verticalSection-main {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  height: auto;

}

.verticalSection-inner-div {
  width: 70%;
}

.verticalSection-upper-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.verticalSection-below-section {
  display: flex;
  flex-direction: row;
}

.verticalSection-upper-text-subtitle {
  text-align: center;
}

.verticalSection-below-section {
  margin-top: 50px;
  justify-content: space-between;
}

.verticalSection-right-side-gif {
  width: 50%;
  display: flex;
  align-items: center;
  }

.verticalSection-tab-selection {
  width: 35%;
}

.features-tabsv2_tab-link {
  width: 25rem;
  max-width: 25rem;
  opacity: .75;
  background-color: #fff;
  border-radius: 16px;
  justify-content: flex-start;
  align-self: auto;
  align-items: center;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  margin-left: 20px;
  padding: 0.5rem 1rem;
  display: flex;
  box-shadow: 0 0 10px rgba(0, 0, 0, .05);
}

.verticalSection-tab-button {
  height: var(--tabHeight);
  background: white;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  display: flex;
  flex-direction: row;
  margin: 20px 0px;
  border-radius: 10px;
  align-items: top;
  padding: 10px 0px;
  animation: decreaseHeightOfButton 0.4s ease-in-out;
  cursor: pointer;

}
.verticalSection-tab-button:hover{
  color: #6A097D;
  box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}

.verticalSection-tab-button-image {
  width: 15%;
}

.verticalSection-tab-button-text {
  margin: auto 0px;
  width: 70%;
}

.verticalSection-tab-selection {


  flex-direction: column;
  justify-content: center;
}

.selected-tab-button {
  /* border: 2px solid red; */
  box-shadow:0 0 10px rgba(66,24,209,.2);
  
  border-radius: 10px;
  height: var(--selectedTabHeight);
  background: #FFF;
  animation: increaseHeightOfButton 0.4s ease-in-out;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; */
}

.selected-right-side-image {
  animation: revealAnimation 1s ease-in-out;
}

.verticalSection-tab-button-subtitle {
  display: none;
}

.verticalSection-tab-button-title {
  color: #474747;
  font-size: 20px;
  font-weight: 600;
  line-height: 150%;
}

.selected-tab-button .verticalSection-tab-button-title {
  color: #6A097D;

}

.selected-verticalSection-tab-button-subtitle {
  display: block;
  animation: showTabDiscription 0.5s ease-in-out;
}

.verticalSection-title{
  font-size: 38px;
}

@keyframes showTabDiscription {
  0% {
    opacity: 0;
    /* opacity: 0; */
  }

  100% {
    opacity: 1;
    /* opacity: 1; */

  }
}

@keyframes revealAnimation {
  0% {
    opacity: 0;
    /* transform: scale(0); */
  }

  100% {
    opacity: 1;
    /* transform: scale(1); */
  }
}

@keyframes increaseHeightOfButton {
  0% {
    height: var(--tabHeight);
    /* opacity: 0; */
  }

  100% {
    height: var(--selectedTabHeight);
    /* opacity: 1; */
  }
}

@keyframes decreaseHeightOfButton {
  0% {
    /* opacity: 0; */
    height: var(--selectedTabHeight);
  }

  100% {
    height: var(--tabHeight);
    /* opacity: 1; */
  }
}



@media (max-width: 1200px) {

  .verticalSection-inner-div {
    width: 85%;
  }
  .verticalSection-below-section {
    margin-top: 90px;
  }
  .verticalSection-right-side-gif {
    width: 55%;
  }
  .verticalSection-tab-selection {
    width: 35%;
  }
  .verticalSection-tab-button-title{
    font-size: 18px;
  }
  .verticalSection-tab-button-subtitle{
    font-size: 13px;
  }

  
    :root {
      --selectedTabHeight: 120px;
      --tabHeight: 50px;
    }
    
}

@media (max-width: 500px) {
  .verticalSection-title{
    font-size: 15px;
    font-weight: 600;
    /* align-items: center; */
    text-align: center;
    /* display: flex; */
    /* justify-content: center; */
  }
}



