/* @import url("https://fonts.googleapis.com/css?family=Open Sans"); */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Roboto:wght@500&display=swap');
/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&family=Roboto:wght@500&display=swap"); */

/* html {
  height: 100%;
  display: flex;
  background: #352F44;
  color: desaturate(#9DF3C4, 15%);
  font-family: 'Poppins', sans-serif;
} */

body {
  /* margin: auto; */
}

.text-animation-container-wrapper {
  justify-content: center;
  display: flex;
  width: "80%";
  height: fit-content;
  min-height: 430px;
}

.writeWizz-animation-text {
  cursor: pointer;
  /* display: block; */
  font-size: 85px;
  /* max-width: 10em; */
  font-weight: 700;
  margin: auto;
  /* line-height: 1; */
  color: #80868B;
  line-height: 120%;
  /* font-family: Open Sans; */
  font-family: 'Roboto', sans-serif;
  /* display: inline  */
}

.writeWizz-animation-title {
  font-size: 85px;
  color: #6a097d;
  line-height: 1.2;
  margin-right: 30px;
  font-weight: 700;
}

/* Add this class to style each line individually */
.ts-line {
  /* display: block;
  overflow: hidden; */
}

@media (max-width: 1300px) and (min-width : 1200px) {
  .writeWizz-animation-title {
    font-size: 10px;
    margin-right: 20px;
  }

  .writeWizz-animation-text {
    font-size: 10px;
  }
}

@media (max-width: 1900px) and (min-width : 1600px) {
  .writeWizz-animation-title {
    font-size: 75px;
    margin-right: 20px;
  }

  .writeWizz-animation-text {
    font-size: 75px;
  }
}

@media (max-width: 1600px) and (min-width : 1400px) {
  .writeWizz-animation-title {
    font-size: 70px;
  }

  .writeWizz-animation-text {
    font-size: 70px;
  }
}

@media (max-width: 1370px) {
  /* .text-animation-container-wrapper {
    min-height: 450px;
    height: fit-content;
  } */
}

@media (max-width: 1400px) and (min-width : 1300px) {
  .writeWizz-animation-title {
    font-size: 65px;
  }

  .writeWizz-animation-text {
    font-size: 65px;
  }
}

@media (max-width: 1300px) and (min-width : 950px) {
  .writeWizz-animation-title {
    font-size: 60px;
    margin-right: 20px;

  }

  .writeWizz-animation-text {
    font-size: 60px;
  }
}


@media (max-width: 950px) {
  .writeWizz-animation-title {
    font-size: 50px;
    margin-right: 15px;

  }

  .writeWizz-animation-text {
    font-size: 50px;
  }

  /* .text-animation-container-wrapper {
    min-height: 420px;
    height: fit-content;
  } */
}

@media (max-width: 600px) {
  .writeWizz-animation-title {
    font-size: 45px;
    margin-right: 10px;

  }

  .writeWizz-animation-text {
    font-size: 45px;
  }
}

@media (max-width: 530px) {
  .writeWizz-animation-title {
    font-size: 37px;
    margin-right: 10px;
  }

  .writeWizz-animation-text {
    font-size: 37px;
  }

  /* .text-animation-container-wrapper {
    min-height: 400px;
    height: fit-content;
  } */
}

@media (max-width: 450px) {
  .writeWizz-animation-title {
    font-size: 37px;
  }

  .writeWizz-animation-text {
    font-size: 37px;
  }
}

@media (max-width: 400px) {
  .writeWizz-animation-title {
    font-size: 37px;
  }

  .writeWizz-animation-text {
    font-size: 37px;
  }
}

/* For Landing page transition text section */

@media (min-width : 1536px) {
  .text-animation-container-wrapper {
    min-height: 425px;
  }
}

@media screen and (max-width : 1535px) and (min-width : 1440px) {
  .text-animation-container-wrapper {
    min-height: 370px;
  }
}

@media screen and (max-width : 1539px) and (min-width : 1200px) {
  .text-animation-container-wrapper {
    min-height: 330px;
  }
}

@media screen and (max-width : 1199px) and (min-width : 900px) {
  .text-animation-container-wrapper {
    min-height: 320px;
  }
}

@media screen and (max-width : 899px) and (min-width : 600px) {
  .text-animation-container-wrapper {
    min-height: 340px;
  }

  .writeWizz-animation-text {
    font-size: 42px;
  }

  .writeWizz-animation-title {
    font-size: 42px;
  }
}





/* For Landing page transition text section */