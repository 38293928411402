.ww-pnf-main-container {
    height: 100vh;
    align-items: center;
    background-color: #FFF;
}

.ww-pnf-logo-item {
    margin: auto !important;
    text-align: center;
}

.ww-pnf-text-item {
    margin: 25px auto 10px !important;
    text-align: center !important;
}


.ww-pnf-text {
    font-size: 47px !important;
    font-weight: 600 !important;
    color: black !important;
}

.ww-pnf-subtext-item {
    text-align: center !important;
    margin: 10px auto !important;
}

.ww-pnf-subtext-text {
    font-size: 18px !important;
    font-weight: 600 !important;
    color: #243B53 !important;
}

.ww-pnf-home-page-btn {
    margin: 15px auto !important;
}