#container {
  /* display: flex; */
  /* flex-direction: column; */
  /* position: absolute; */
  /* overflow: auto; */
  /* top: 0;
  left: 0;
  width: 100%;
  height: 100%; */
  /* scroll-snap-type: y mandatory; */
}

.one {
  height: 100vh;
}

.two {
  height: 100vh;
}

.first-page {
  min-height: 100%;
  height: 100vh;
  background-color: #f7de8f;
}

.second-page {
  min-height: 100%;
  height: 100vh;

  background-color: #ecc5ff;
}

.third-page {
  height: 100vh;
  min-height: 100%;
  background-color: #bffbff;
}

.fourth-page {
  min-height: 100%;
  height: 100vh;

  background-color: #cefde8;
}


.page {
  display: flex;
  justify-content: center;
  align-items: center;

}

.table-view {
  display: flex;
  width: 75%;
  justify-content: space-between;
}

.left-section {
  width: 50%;

}

.right-section {
  width: 50%;
}

.heading-is-black {
  font-size: 48px;
  opacity: 1;
}

.heading-is-yellow {
  font-size: 48px;
  color: yellow;
}

.right-section {
  width: 50%;
}


/* CSS FOR PAGE INDICATOR*/

.parent-div {
  /* Your existing styles for the parent div */
}

.GSAPScroller-content-wrapper {
  position: relative;
  /* Make sure the section is relatively positioned */
}

.GSAPScroller-content-wrapper .page-indicator {
  position: fixed;
  top: 40%;
  left: 5%;
  background-color: transparent;
  padding: 10px;
  border: 1px solid #ccc;
  z-index: 1000;
  border: none;
  display: none;
}

.indicator {
  width: 10px;
  height: 10px;
  margin-top: 10px !important;
  background-color: rgb(170, 165, 165);
  border-radius: 5px;
  margin: 0 8px;
  cursor: pointer;
}

.active-indicator {
  background-color: black;
  height: 30px;
  animation: active-indicator-move-animation 0.3s ease-in-out ;
}

@keyframes active-indicator-move-animation {
  0% {
    height: 15px;
  }
  25% {
    height: 20px;
  }
  50% {
    height: 25px;
  }
  100% {
    height: 30px;
  }
}


/* MEDIA QUEARY OF 1200 PX */

@media (max-width: 1200px) {

  .GSAPScroller-content-wrapper .page-indicator {
    left: 1%;
  }

  .table-view {
    width: 85%;
  }

  .GSAPScroller-text-discription {
    font-size: 16px
  }

  .heading-is-black {
    font-size: 38px;
  }
}