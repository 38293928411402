.cw-bz-head-item {
    margin: 0 auto !important;
}

.cw-bz-circle-img-item {
    margin: 0px 0px 0px !important;
    height: 390px;
}

.cw-bz-circle-head-item {
    border-bottom: 2px solid #6A097D;
    padding-bottom: 2px !important;
    margin: 10px auto !important;
}


.cw-bz-typography-head-item {
    margin: 10px auto !important;
}


.cw-bz-circle-head {
    font-size: 15px !important;
    font-weight: 600 !important;
    color: #6A097D !important;
    letter-spacing: 3px !important;
}

.cw-bz-typography-head {
    font-size: 18px !important;
    font-weight: 600 !important;
    color: #1F384C !important;
}

.cw-bz-sub-typography-head {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #1F384C !important;
}

.cw-circle-img {
    border-radius: 50% !important;
    width: 85% !important;
    height: 100% !important;
    /* height: 80% !important; */
}

.cw-bz-circle-main-container {
    justify-content: space-between;
    /* gap: 100px; */
}

.cw-bz-main-head-label {
    font-size: 35px !important;
    font-weight: 500 !important;
    color: #1F384C !important;
}

.cw-bz-main-head-label-item {
    text-align: center !important;
    margin: 15px auto !important;
}

.cw-bz-main-subhead-label-item {
    text-align: center !important;
    margin: 2px auto !important;
}

.cw-bz-main-subhead-label {
    font-size: 20px !important;
    font-weight: 500 !important;
    color: #1F384C !important;
    width: 100% !important;
}

.cw-bz-subhead-label {
    text-align: center !important;
    margin: 10px auto !important;
    font-size: 20px !important;
    font-weight: 500 !important;
    color: #1F384C !important;
    width: 100% !important;
}

.cw-bz-circle-main-item {
    margin: 40px auto !important;
}

.cw-bz-circle-btn {
    background-color: #6A097D !important;
    color: white !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    border-radius: 5px !important;
    padding: 8px 20px !important;
    text-transform: none !important;
}