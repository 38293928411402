/* Alternative page info section */

.ap-card-ifream-item {
    margin: 0 auto !important;
}

.ap-highlighted-text {
    color: #6A097D !important;
    font-size: 30px !important;
    font-weight: 600 !important;
}

.ap-normal-text {
    color: black !important;
    font-size: 30px !important;
    font-weight: 600 !important;
}

.alternative-page-card-item {
    margin: 50px auto 0px !important;
}

.alternative-page-card-container {
    justify-content: center;
}

.alternative-page-card-sub-item {
    margin: 20px 30px !important;
}

.ap-card-ifream-container {
    justify-content: space-between;
}


@media only screen and (min-width: 320px) and (max-width: 899px) {

    .alternative-page-info-section-header {
        margin-top: 20px !important;
    }
}

@media only screen and (min-width: 320px) and (max-width: 599px) {

    .ap-normal-text {
        color: black !important;
        font-size: 24px !important;
        font-weight: 600 !important;
    }

    .ap-highlighted-text {
        color: #6A097D !important;
        font-size: 24px !important;
        font-weight: 600 !important;
    }

    .alternative-page-info-section-text {
        font-size: 15px !important;
        font-weight: 500 !important;
        color: black !important;
    }
}