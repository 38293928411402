.brsw-component-main-item {
    margin: 50px auto 20px !important;
}

.brsw-footer-component-main-item {
    margin-top: -90px !important;
}

.brsw-component-border {
    width: 92%;
    margin: auto;
    border-bottom: 2px solid black;
}
@media only screen and (min-width: 0px) and (max-width: 600px) {
    .brsw-component-main-item {
        margin: 0px !important;
    }
}
